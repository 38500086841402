import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Award {
  account: string;
  quantity: number;
  registerTime: number | string;
}

export interface Recommend {
  account: string;
  buyRate: number;
  sellRate: number;
  registerTime: number | string;
}

export interface RecommendReg {
  buyRate: number;
  sellRate: number;
  registerTime: number | string;
  code: string;
  url: string;
  showInfo: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RecommendRebateService {
  award$ = new BehaviorSubject<Award[]>([]);
  recommend$ = new BehaviorSubject<Recommend[]>([]);
  recommendReg$ = new BehaviorSubject<RecommendReg[]>([]);

  constructor() {
    this.init();
  }

  init() {
    this.getAward();
    this.getRecommend();
    this.getRecommendReg();
  }

  getAward() {
    const award = [
      { account: 'Max', quantity: 97, registerTime: 1717579383000 },
      { account: 'Murphy', quantity: 81, registerTime: 1717398663000 },
      { account: 'Roy', quantity: 75, registerTime: '2024/06/02 09:47:01' },
      { account: 'Cave', quantity: 79, registerTime: '2024/06/01 19:10:31' },
      { account: 'Ann', quantity: 63, registerTime: '2024/05/31 17:01:28' },
      { account: 'Luean', quantity: 62, registerTime: '2024/05/25 12:17:25' },
      { account: 'Beach', quantity: 73, registerTime: '2024/05/14 09:47:01' },
      { account: 'Towel', quantity: 56, registerTime: '2024/05/08 19:10:31' },
      { account: 'Frisbee', quantity: 69, registerTime: '2024/05/03 17:01:28' },
    ];
    this.award$.next(award);
  }

  getRecommend() {
    const recommend = [
      { account: 'Max', buyRate: 4.7, sellRate: 4.7, registerTime: '2024/04/21' },
      { account: 'Murphy', buyRate: 2.5, sellRate: 2.5, registerTime: '2024/04/15' },
      { account: 'Roy', buyRate: 3.1, sellRate: 3.1, registerTime: '2024/04/13' },
      { account: 'Cave', buyRate: 4.7, sellRate: 4.7, registerTime: '2024/04/11' },
      { account: 'Ann', buyRate: 2.5, sellRate: 2.5, registerTime: '2024/04/09' },
      { account: 'Luean', buyRate: 3.1, sellRate: 3.1, registerTime: '2024/04/05' },
      { account: 'Beach', buyRate: 4.7, sellRate: 4.7, registerTime: '2024/04/03' },
      { account: 'Beach', buyRate: 2.5, sellRate: 2.5, registerTime: '2024/04/02' },
      { account: 'Towel', buyRate: 3.1, sellRate: 3.1, registerTime: '2024/04/01' },
      { account: 'Frisbee', buyRate: 4.7, sellRate: 4.7, registerTime: '2024/04/01' },
    ];
    this.recommend$.next(recommend);
  }

  getRecommendReg() {
    const recommendReg = [
      {
        buyRate: 2.5,
        sellRate: 2.5,
        registerTime: '2024/04/30',
        code: 'ASde3P3jTRwOC8ASde3P3jTRwOC8',
        url: 'https://google.com.tw https://google.com.tw',
        showInfo: false,
      },
      {
        buyRate: 3.1,
        sellRate: 3.1,
        registerTime: '2024/04/27',
        code: 'ASde3P3jTRwOC8',
        url: 'https://google.com.tw',
        showInfo: false,
      },
      {
        buyRate: 4.7,
        sellRate: 4.7,
        registerTime: '2024/04/21',
        code: 'ASde3P3jTRwOC8',
        url: 'https://google.com.tw',
        showInfo: false,
      },
      {
        buyRate: 2.5,
        sellRate: 2.5,
        registerTime: '2024/04/16',
        code: 'ASde3P3jTRwOC8ASde3P3jTRwOC8',
        url: 'https://google.com.tw https://google.com.tw',
        showInfo: false,
      },
      {
        buyRate: 3.1,
        sellRate: 3.1,
        registerTime: '2024/04/13',
        code: 'ASde3P3jTRwOC8',
        url: 'https://google.com.tw',
        showInfo: false,
      },
      {
        buyRate: 4.7,
        sellRate: 4.7,
        registerTime: '2024/04/11',
        code: 'ASde3P3jTRwOC8',
        url: 'https://google.com.tw',
        showInfo: false,
      },
    ];
    this.recommendReg$.next(recommendReg);
  }
}
