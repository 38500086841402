import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../core';

@Pipe({
  name: 'appMinutesDecimalFormat',
})
export class MinutesDecimalFormatPipe implements PipeTransform {
  transform(value: number, decimal?: number): string {
    return Utils.minutesDecimalFormat(value, decimal);
  }
}
