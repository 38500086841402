import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslationsService, UserService } from '@lib';

export enum InputTypeEnum {
  Account = 'account',
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
  InvitationCode = 'invitationCode',
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  inputType = InputTypeEnum;
  registerForm!: FormGroup;

  account: string | null = null;
  isAccountFocus = false;
  isAccountFill = false;
  accountPlaceholder: string | null = null;

  password: string | null = null;
  isPasswordFocus = false;
  isPasswordFill = false;
  passwordPlaceholder: string | null = null;

  confirmPassword: string | null = null;
  isConfirmPasswordFocus = false;
  isConfirmPasswordFill = false;
  confirmPasswordPlaceholder: string | null = null;

  invitationCode: string | null = null;
  isInvitationCodeFocus = false;
  isInvitationCodeFill = false;
  invitationCodePlaceholder: string | null = null;

  get onSubmitStyle(): string {
    if (this.registerForm.invalid) return 'btn-flat-disabled-gray';
    return 'btn-flat-yellow';
  }

  constructor(
    public router: Router,
    public translationsSer: TranslationsService,
    private formBuilder: FormBuilder,
    private userSer: UserService
  ) {}

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      account: ['', [this.accountValidator]],
      password: ['', [this.passwordValidator]],
      confirmPassword: ['', [this.confirmPasswordValidator]],
      invitationCode: ['', [Validators.required]],
    });
    this.setPlaceholder();
  }

  async setPlaceholder() {
    await this.translationsSer
      .get('inputText')
      .then(data => {
        // 请输入账号
        this.accountPlaceholder = data.accountPlaceholder;
        // 请输入密码
        this.passwordPlaceholder = data.passwordPlaceholder;
        // 请输入确认密码
        this.confirmPasswordPlaceholder = data.confirmPasswordPlaceholder;
        // 请输入邀请码
        this.invitationCodePlaceholder = data.invitationCodePlaceholder;
      })
      .catch(e => console.log(e));
  }

  accountValidator = (control: FormControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value === null) {
      return { error: true, pattern: true };
    }
    return {};
  };

  passwordValidator = (control: FormControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value == null) {
      return { error: true, pattern: true };
    }
    return {};
  };

  confirmPasswordValidator = (control: FormControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value == null) {
      return { error: true, pattern: true };
    }
    return {};
  };

  inputKeyup(event: Event) {
    if (this.registerForm.invalid) event.preventDefault();
  }

  isError(inputType: InputTypeEnum) {
    return this.registerForm.controls[inputType].invalid && this.registerForm.controls[inputType].dirty;
  }

  inputFocus(inputType: InputTypeEnum) {
    switch (inputType) {
      case InputTypeEnum.Account:
        this.isAccountFill = true;
        this.isAccountFocus = true;
        break;
      case InputTypeEnum.Password:
        this.isPasswordFill = true;
        this.isPasswordFocus = true;
        break;
      case InputTypeEnum.ConfirmPassword:
        this.isConfirmPasswordFill = true;
        this.isConfirmPasswordFocus = true;
        break;
      case InputTypeEnum.InvitationCode:
        this.isInvitationCodeFill = true;
        this.isInvitationCodeFocus = true;
        break;
      default:
        return;
    }
  }

  inputBlur(inputType: InputTypeEnum) {
    switch (inputType) {
      case InputTypeEnum.Account:
        this.isAccountFocus = false;
        break;
      case InputTypeEnum.Password:
        this.isPasswordFocus = false;
        break;
      case InputTypeEnum.ConfirmPassword:
        this.isConfirmPasswordFocus = false;
        break;
      case InputTypeEnum.InvitationCode:
        this.isInvitationCodeFocus = false;
        break;
      default:
        return;
    }
    this.inputChange(inputType);
  }

  inputChange(inputType: InputTypeEnum) {
    switch (inputType) {
      case InputTypeEnum.Account:
        if (this.registerForm.controls[inputType].value) this.isAccountFill = true;
        else this.isAccountFill = false;
        break;
      case InputTypeEnum.Password:
        if (this.registerForm.controls[inputType].value) this.isPasswordFill = true;
        else this.isPasswordFill = false;
        break;
      case InputTypeEnum.ConfirmPassword:
        if (this.registerForm.controls[inputType].value) this.isConfirmPasswordFill = true;
        else this.isConfirmPasswordFill = false;
        break;
      case InputTypeEnum.InvitationCode:
        if (this.registerForm.controls[inputType].value) this.isInvitationCodeFill = true;
        else this.isInvitationCodeFill = false;
        break;
      default:
        return;
    }
  }

  input(inputType: InputTypeEnum) {
    this.setFormControls(inputType);
  }

  setFormControls(inputType: InputTypeEnum | string) {
    this.registerForm.controls[inputType].markAsDirty();
    this.registerForm.controls[inputType].updateValueAndValidity();
  }

  onSubmit() {
    for (const i in this.registerForm.controls) {
      this.setFormControls(i);
    }
    if (this.registerForm.invalid) return;
    this.userSer.register(this.registerForm.value).then((res: any) => {
      console.log('res', res);
      if (res && res.code == 200) this.router.navigate(['/login']);
    });
  }
}
