import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatBottomSheetConfig, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

export interface BottomSheetConfig<T = any, R = any> {
  component: ComponentType<T>;
  config: MatBottomSheetConfig;
  callback?: (ref: MatBottomSheetRef<T, R>) => void;
}

export interface DialogConfig<T = any, R = any> {
  component: ComponentType<T>;
  config: MatDialogConfig;
  callback?: (ref: MatDialogRef<T, R>) => void;
}

export interface NotifyMessage {
  level?: 'done' | 'warning' | 'error';
  showIcon?: boolean;
  title?: string;
  text: string;
  confirmText?: string;
  confirmAction?: () => void;
  cancelText?: string;
  cancelAction?: () => void;
  textAsHtml?: boolean; // 將 text 以 html 進行 render
  minWidth?: string;
  autoDismiss?: number;
}

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  bottomSheet$ = new Subject<BottomSheetConfig>();
  dialog$ = new Subject<DialogConfig>();
  notify$ = new Subject<MatDialogConfig<NotifyMessage>>();

  constructor() {}

  showNotify(level: 'done' | 'warning' | 'error', text: string, autoDismiss: number = 1500) {
    this.notify$.next({
      data: { level, text, autoDismiss },
    });
  }
}
