import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslationsService, UserService } from '@lib';

export enum InputTypeEnum {
  AuthCode = 'authCode',
}

interface DialogData {}

@Component({
  selector: 'app-login-authenticator',
  templateUrl: './login-authenticator.component.html',
  styleUrls: ['./login-authenticator.component.scss'],
})
export class LoginAuthenticatorComponent implements OnInit {
  inputType = InputTypeEnum;
  inputForm!: FormGroup;

  authCode?: string | null = null;
  isAuthCodeFocus = false;
  isAuthCodeFill = false;
  authCodePlaceholder: string | null = null;

  get onSubmitStyle(): string {
    if (this.inputForm.invalid) return 'btn-flat-disabled-gray';
    return 'btn-flat-yellow';
  }

  constructor(
    @Optional() public dialogRef: MatDialogRef<LoginAuthenticatorComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    public translationsSer: TranslationsService,
    private formBuilder: FormBuilder,
    private userSer: UserService
  ) {}

  ngOnInit(): void {
    this.inputForm = this.formBuilder.group({
      authCode: ['', [Validators.required]],
    });
    this.setPlaceholder();
  }

  async setPlaceholder() {
    await this.translationsSer
      .get('inputText')
      .then(data => {
        // 请输入验证码
        this.authCodePlaceholder = data.authCodePlaceholder;
      })
      .catch(e => console.log(e));
  }

  inputKeyup(event: Event) {
    if (this.inputForm.invalid) event.preventDefault();
  }

  isError(inputType: InputTypeEnum) {
    return this.inputForm.controls[inputType].invalid && this.inputForm.controls[inputType].dirty;
  }

  inputFocus(inputType: InputTypeEnum) {
    switch (inputType) {
      case InputTypeEnum.AuthCode:
        this.isAuthCodeFocus = true;
        this.isAuthCodeFill = true;
        break;
      default:
        return;
    }
  }

  inputBlur(inputType: InputTypeEnum) {
    switch (inputType) {
      case InputTypeEnum.AuthCode:
        this.isAuthCodeFocus = false;
        break;
      default:
        return;
    }
    this.inputChange(inputType);
  }

  inputChange(inputType: InputTypeEnum) {
    switch (inputType) {
      case InputTypeEnum.AuthCode:
        if (this.inputForm.controls[inputType].value) this.isAuthCodeFill = true;
        else this.isAuthCodeFill = false;
        break;
      default:
        return;
    }
  }

  input(inputType: InputTypeEnum) {
    this.setFormControls(inputType);
  }

  setFormControls(inputType: InputTypeEnum | string) {
    this.inputForm.controls[inputType].markAsDirty();
    this.inputForm.controls[inputType].updateValueAndValidity();
  }

  resetFormValue(inputType: InputTypeEnum | string) {
    this.inputForm.controls[inputType].reset();
  }

  onSubmit() {
    for (const i in this.inputForm.controls) {
      this.setFormControls(i);
    }
    if (this.inputForm.invalid) return;
    this.userSer.loginAuthCode(this.inputForm.value).then((res: any) => {
      if (res && res.code == 200) this.close(true);
    });
  }

  close(isFeedback: boolean = false) {
    this.dialogRef?.close(isFeedback);
  }
}
