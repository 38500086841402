export enum EnvType {
  none = 0,
  local,
  dev,
  staging,
  prod,
}

export enum VisionType {
  web,
  app,
}

export enum VisionModeType {
  none = '',
  h5 = 'h5',
  native = 'native',
  pwa = 'pwa',
}

export enum MyLoggerLevelEnum {
  info,
  warn,
  error,
}

export enum DeviceType {
  Android = 'Android',
  WindowsPhone = 'windows phone',
  IOS = 'iOS',
  Unknown = 'unknown',
}

// export enum LocalStorageKey {
//   Domain = 'Domain',
//   UserInfo = 'userInfo',
//   DeviceId = 'DeviceId',
//   GeoipCache = 'GeoipCache',
//   SavePassword = 'SavePassword',
//   SkipModal = 'SkipModal',
//   SkinApp = 'SkinApp',
//   SkinWeb = 'SkinWeb',
//   RegionLanguage = 'RegionLanguage',
//   Cookies = 'Cookies',
//   ShowProfitEndNotice = 'ShowProfitEndNotice',
//   Lang = 'Lang',
//   MemoryLayoutWeb = 'memoryLayoutWeb',
//   MemoryTabListWeb = 'memoryTabListWeb',
//   RealWallet = 'RealWallet',
//   MenuNode = 'MenuNode',
//   TransactionStatus = 'TransactionStatus',
//   TransactionStatusList = 'TransactionStatusList',
//   VisitorRecordId = 'VisitorRecordId',
//   Tutorial = 'Tutorial'
// }

export enum ResetFlag {
  ALL,
  PASSWORD,
  CAPTCHA,
  OTP_GOOGLE,
  BANKCARD,
}

export enum EnumLayoutId {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

export enum Tutorial {
  Main,
  BuyInsurance,
  InsuranceTrading,
  NormalTrading,
  Checking,
}

export enum AppFilterSelectOptionEnum {
  Title = 'title',
  All = 'all',
}

/** KRChart k線類型，之後移至KRChart lib內 */
export enum ChartType {
  Line = 1,
  Candle = 2,
}

/** KRChart 技術線類型，之後移至KRChart lib內 */
export enum IndicatorType {
  SMA = 'SMA',
  SMA1 = 'SMA1',
  SMA2 = 'SMA2',
  SMA3 = 'SMA3',
  BB = 'Bollinger Bands',
  MACD = 'MACD',
  KDJ = 'KDJ',
  RSI = 'RSI',
  VOL = 'VOL',
}

export enum ThirdPartyBehavior {
  Login,
  Register,
}

// export enum enumCommandKey {
//   none = 0,
//   passAItemValue,
//   userInfo,
//   showCustomerServiceButtom,
//   showModalConfirm,
//   showModalReload,
//   showModalGameProcessor,
//   showMOdalBandCardAdding,
//   reloadBandCardList,
//   showModalTest,
//   showLotteryrConfrimModal,
//   showLotteryrTwoSideConfrimModal,
//   getUserInfo,
//   showModalFinanceDetails,
//   dynamicModal,
//   dynamicSlideInPage,
//   loading,
// }
