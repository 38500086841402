<div class="select-language">
  <div class="dialog-nav">
    <div class="dialog-title">{{ translationsSer.selectLanguage.title | translate }}</div>
    <button class="dialog-close-btn" (click)="close()">
      <mat-icon class="dialog-close-icon">close</mat-icon>
    </button>
  </div>

  <div class="dialog-content">
    <div class="search-language">
      <div class="input-stroked-deep">
        <mat-icon class="search-icon">search</mat-icon>
        <input
          [(ngModel)]="searchLanguage"
          type="text"
          [placeholder]="languagePlaceholder"
          (input)="changeSearch($event)" />
        <button *ngIf="searchLanguage" class="clear-btn" (click)="clear()">
          <mat-icon class="clear-icon">close</mat-icon>
        </button>
      </div>
    </div>

    <div class="language-list">
      <div *ngFor="let lang of dataList" class="language" [class.active]="lang.key === langKey" (click)="select(lang)">
        {{ lang.description }}
      </div>
    </div>
  </div>
</div>
