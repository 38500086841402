import { Component, OnInit } from '@angular/core';
import { LanguageEnum, Maybe, MessageService, TranslationsService } from '@lib';
import { filter } from 'rxjs';
import { SelectLanguageComponent } from '../select-language/select-language.component';

export enum FooterLinkEnum {
  Language,
  Cookies,
  Terms,
  PrivacyStatement,
}

export interface FooterLink {
  id: FooterLinkEnum;
  label: Maybe<string>;
  router: string;
}

export enum SelectTypeEnum {
  Language,
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  footerLink = FooterLinkEnum;
  footerLinkList: FooterLink[] = [];

  langDescription: string | null = null;
  langKey: string = LanguageEnum.ZH_CN;
  selectType = SelectTypeEnum;

  constructor(
    public translationsSer: TranslationsService,
    private messageSer: MessageService
  ) {}

  ngOnInit(): void {
    this.translationsSer.getSupportedLang();
    this.langKey = this.translationsSer.curLang$.value;
    this.translationsSer.langList.forEach(item => {
      if (item.key === this.langKey) this.langDescription = item.description;
      return;
    });
    this.setFooterLinkt();
  }

  async setFooterLinkt() {
    await this.translationsSer
      .get('footer')
      .then(data => {
        this.footerLinkList = [
          { id: FooterLinkEnum.Language, label: this.langDescription, router: '' }, // Language
          { id: FooterLinkEnum.Cookies, label: 'Cookies', router: '' }, // Cookies
          { id: FooterLinkEnum.Terms, label: data.terms, router: '' }, // 条款
          { id: FooterLinkEnum.PrivacyStatement, label: data.privacyStatement, router: '' }, // 隐私说明
        ];
      })
      .catch(e => console.log(e));
  }

  selectLanguage() {
    const params = { langKey: this.langKey };
    this.openDialog(params, SelectTypeEnum.Language);
  }

  openDialog(params?: { langKey: string }, type?: number): void {
    let component;
    const disableClose = false;
    switch (type) {
      case SelectTypeEnum.Language:
        component = SelectLanguageComponent;
        break;
      default:
        return;
    }

    this.messageSer.dialog$.next({
      component: component,
      config: {
        maxWidth: '420px',
        minWidth: '420px',
        maxHeight: '90vh',
        disableClose: disableClose,
        data: {
          ...params,
        },
      },
      callback: ref => {
        ref
          .afterClosed()
          .pipe(filter(confirm => !!confirm))
          .subscribe({
            next: resp => {
              switch (type) {
                case SelectTypeEnum.Language:
                  this.langDescription = resp.description;
                  this.langKey = resp.key;
                  break;
                default:
                  break;
              }
              this.reset();
            },
          });
      },
    });
  }

  reset() {
    console.log('close');
  }

  routerLinkActive(url: string) {
    console.log('routerLinkActive', url);
  }
}
