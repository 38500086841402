import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../core';

@Pipe({
  name: 'appMoneyFormat',
})
export class MoneyFormatPipe implements PipeTransform {
  transform(value: number | string, needComma?: boolean, digit?: number) {
    return Utils.moneyFormat(value, needComma, digit) as number;
  }
}
