import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PaymentEnum, Payment, TradeTypeEnum } from './category-options.service';
import { Maybe } from '../types';

export enum UserTradeStateEnum {
  Allow,
  NotAllow,
}

export interface Transaction {
  nickname: string;
  router: string;
  tradeQty: number;
  orderRate: number;
  price: number;
  currency: string | null;
  cryptocurrencyAmount: number | string;
  cryptocurrency: string | null;
  minLimit: number;
  maxLimit: number;
  paymentTimeLimit: number;
  averageDeliveryTime: number;
  userTradeState: UserTradeStateEnum;
  terms: string;
  paymentsList: Payment[];
}

export interface TransactionData extends Transaction {
  tradeType?: TradeTypeEnum;
  showDetail?: boolean;
}

export interface OrderCreationData extends TransactionData {
  amount: string;
  cryptocurrencyAmount: string;
  paymentData: Payment;
}

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  transaction$ = new BehaviorSubject<TransactionData[]>([]);

  orderCreationData$ = new BehaviorSubject<Maybe<OrderCreationData>>(<Maybe<OrderCreationData>>{});

  constructor() {
    this.orderCreationData$.next(null);
    this.getTransaction();
  }

  getTransaction() {
    const transaction = [
      {
        tradeType: TradeTypeEnum.Buy,
        nickname: 'A测式单01',
        router: '',
        tradeQty: 51,
        orderRate: 39.99,
        price: 32.23,
        currency: 'PHP',
        cryptocurrencyAmount: 11985.61,
        cryptocurrency: 'USDT',
        minLimit: 300000.0,
        maxLimit: 450000.0,
        paymentTimeLimit: 900,
        averageDeliveryTime: 380.4,
        userTradeState: UserTradeStateEnum.Allow,
        terms:
          '购买 USDT请验证\n1.请先加入『azpapa』Line\nID:@ztn9069t，量大可以\n2.银行转帐准备好合法证件,核对身份确保本人自愿交易，请在一张白纸上面写上「今天日期+姓名+本人于弊安交易验证用」字样，搭配一张身分证明文件一起拍照\n3.上传证件&存折封面到Line进行验证\n只收验证过的帐号转入款项。\n最近诈骗手法琳琅满目,请务必小心!\n某人指定你找azpapa买币是诈骗\n网友教你来操作买币是骗\n交友软体教你来买币是骗\n听信网友把货币提现是诈骗\n平台获利却无法提领是诈骗\n平台叫你去汇款缴税是骗\n用大平台才安心\n免责声明:『azpapa』售出放行之usdt属于买家自身财产,所有投资理财请您谨慎评估,并自行负责,若你依然转出交易所遭诈骗报案末与警方清楚交代银货两讫后行为与 卖家无关导致我方银行帐户无辜被冻结,必定提出刑事及民事损害赔偿告诉切勿以不法资金买卖\n交易,违者必究,请勿以身试法。 提醒买家,请具体了解警政署(防骗专线165)所提供之诈骗平台清单,避免将加密货币打入受骗,『azpapa』不为买方之理财行为负责。',
        paymentsList: [
          { id: PaymentEnum.Bank, label: '银行转账', color: 'yellow' },
          { id: PaymentEnum.AliPay, label: 'Alipay', color: 'blue' },
          { id: PaymentEnum.JkoPay, label: '街口转帐', color: 'pink' },
        ],
      },
      {
        tradeType: TradeTypeEnum.Sell,
        nickname: 'B测式单01',
        router: '',
        tradeQty: 47,
        orderRate: 49.99,
        price: 35.09,
        currency: 'PHP',
        cryptocurrencyAmount: 34234.15,
        cryptocurrency: 'USDT',
        minLimit: 200000.0,
        maxLimit: 350000.0,
        paymentTimeLimit: 900,
        averageDeliveryTime: 380.4,
        userTradeState: UserTradeStateEnum.Allow,
        terms:
          '购买 USDT请验证\n1.请先加入『azpapa』Line\nID:@ztn9069t，量大可以\n2.银行转帐准备好合法证件,核对身份确保本人自愿交易，请在一张白纸上面写上「今天日期+姓名+本人于弊安交易验证用」字样，搭配一张身分证明文件一起拍照\n3.上传证件&存折封面到Line进行验证\n只收验证过的帐号转入款项。\n最近诈骗手法琳琅满目,请务必小心!\n某人指定你找azpapa买币是诈骗\n网友教你来操作买币是骗\n交友软体教你来买币是骗\n听信网友把货币提现是诈骗\n平台获利却无法提领是诈骗\n平台叫你去汇款缴税是骗\n用大平台才安心\n免责声明:『azpapa』售出放行之usdt属于买家自身财产,所有投资理财请您谨慎评估,并自行负责,若你依然转出交易所遭诈骗报案末与警方清楚交代银货两讫后行为与 卖家无关导致我方银行帐户无辜被冻结,必定提出刑事及民事损害赔偿告诉切勿以不法资金买卖\n交易,违者必究,请勿以身试法。 提醒买家,请具体了解警政署(防骗专线165)所提供之诈骗平台清单,避免将加密货币打入受骗,『azpapa』不为买方之理财行为负责。',
        paymentsList: [
          { id: PaymentEnum.JkoPay, label: '街口转帐', color: 'pink' },
          { id: PaymentEnum.LineBank, label: 'LINE Bank', color: 'green' },
        ],
      },
      {
        tradeType: TradeTypeEnum.Buy,
        nickname: 'C测式单01',
        router: '',
        tradeQty: 27,
        orderRate: 59.99,
        price: 31.89,
        currency: 'PHP',
        cryptocurrencyAmount: 45356.1,
        cryptocurrency: 'USDT',
        minLimit: 100000.0,
        maxLimit: 250000.0,
        paymentTimeLimit: 900,
        averageDeliveryTime: 380.4,
        userTradeState: UserTradeStateEnum.Allow,
        terms:
          '购买 USDT请验证\n1.请先加入『azpapa』Line\nID:@ztn9069t，量大可以\n2.银行转帐准备好合法证件,核对身份确保本人自愿交易，请在一张白纸上面写上「今天日期+姓名+本人于弊安交易验证用」字样，搭配一张身分证明文件一起拍照\n3.上传证件&存折封面到Line进行验证\n只收验证过的帐号转入款项。\n最近诈骗手法琳琅满目,请务必小心!\n某人指定你找azpapa买币是诈骗\n网友教你来操作买币是骗\n交友软体教你来买币是骗\n听信网友把货币提现是诈骗\n平台获利却无法提领是诈骗\n平台叫你去汇款缴税是骗\n用大平台才安心\n免责声明:『azpapa』售出放行之usdt属于买家自身财产,所有投资理财请您谨慎评估,并自行负责,若你依然转出交易所遭诈骗报案末与警方清楚交代银货两讫后行为与 卖家无关导致我方银行帐户无辜被冻结,必定提出刑事及民事损害赔偿告诉切勿以不法资金买卖\n交易,违者必究,请勿以身试法。 提醒买家,请具体了解警政署(防骗专线165)所提供之诈骗平台清单,避免将加密货币打入受骗,『azpapa』不为买方之理财行为负责。',
        paymentsList: [
          { id: PaymentEnum.AliPay, label: 'Alipay', color: 'blue' },
          { id: PaymentEnum.LinePay, label: 'LINE Pay', color: 'green' },
        ],
      },
      {
        tradeType: TradeTypeEnum.Buy,
        nickname: 'D测式单01',
        router: '',
        tradeQty: 38,
        orderRate: 69.99,
        price: 31.89,
        currency: 'CNY',
        cryptocurrencyAmount: 45356.1,
        cryptocurrency: 'BTC',
        minLimit: 100000.0,
        maxLimit: 250000.0,
        paymentTimeLimit: 900,
        averageDeliveryTime: 380.4,
        userTradeState: UserTradeStateEnum.Allow,
        terms:
          '购买 USDT请验证\n1.请先加入『azpapa』Line\nID:@ztn9069t，量大可以\n2.银行转帐准备好合法证件,核对身份确保本人自愿交易，请在一张白纸上面写上「今天日期+姓名+本人于弊安交易验证用」字样，搭配一张身分证明文件一起拍照\n3.上传证件&存折封面到Line进行验证\n只收验证过的帐号转入款项。\n最近诈骗手法琳琅满目,请务必小心!\n某人指定你找azpapa买币是诈骗\n网友教你来操作买币是骗\n交友软体教你来买币是骗\n听信网友把货币提现是诈骗\n平台获利却无法提领是诈骗\n平台叫你去汇款缴税是骗\n用大平台才安心\n免责声明:『azpapa』售出放行之usdt属于买家自身财产,所有投资理财请您谨慎评估,并自行负责,若你依然转出交易所遭诈骗报案末与警方清楚交代银货两讫后行为与 卖家无关导致我方银行帐户无辜被冻结,必定提出刑事及民事损害赔偿告诉切勿以不法资金买卖\n交易,违者必究,请勿以身试法。 提醒买家,请具体了解警政署(防骗专线165)所提供之诈骗平台清单,避免将加密货币打入受骗,『azpapa』不为买方之理财行为负责。',
        paymentsList: [
          { id: PaymentEnum.AliPay, label: 'Alipay', color: 'blue' },
          { id: PaymentEnum.LinePay, label: 'LINE Pay', color: 'green' },
        ],
      },
      {
        tradeType: TradeTypeEnum.Buy,
        nickname: 'E测式单01',
        router: '',
        tradeQty: 16,
        orderRate: 79.99,
        price: 34.01,
        currency: 'PHP',
        cryptocurrencyAmount: 45356.1,
        cryptocurrency: 'USDT',
        minLimit: 100000.0,
        maxLimit: 250000.0,
        paymentTimeLimit: 900,
        averageDeliveryTime: 380.4,
        userTradeState: UserTradeStateEnum.Allow,
        terms:
          '购买 USDT请验证\n1.请先加入『azpapa』Line\nID:@ztn9069t，量大可以\n2.银行转帐准备好合法证件,核对身份确保本人自愿交易，请在一张白纸上面写上「今天日期+姓名+本人于弊安交易验证用」字样，搭配一张身分证明文件一起拍照\n3.上传证件&存折封面到Line进行验证\n只收验证过的帐号转入款项。\n最近诈骗手法琳琅满目,请务必小心!\n某人指定你找azpapa买币是诈骗\n网友教你来操作买币是骗\n交友软体教你来买币是骗\n听信网友把货币提现是诈骗\n平台获利却无法提领是诈骗\n平台叫你去汇款缴税是骗\n用大平台才安心\n免责声明:『azpapa』售出放行之usdt属于买家自身财产,所有投资理财请您谨慎评估,并自行负责,若你依然转出交易所遭诈骗报案末与警方清楚交代银货两讫后行为与 卖家无关导致我方银行帐户无辜被冻结,必定提出刑事及民事损害赔偿告诉切勿以不法资金买卖\n交易,违者必究,请勿以身试法。 提醒买家,请具体了解警政署(防骗专线165)所提供之诈骗平台清单,避免将加密货币打入受骗,『azpapa』不为买方之理财行为负责。',
        paymentsList: [
          { id: PaymentEnum.AliPay, label: 'Alipay', color: 'blue' },
          { id: PaymentEnum.LinePay, label: 'LINE Pay', color: 'green' },
        ],
      },
      {
        tradeType: TradeTypeEnum.Sell,
        nickname: 'F测式单01',
        router: '',
        tradeQty: 27,
        orderRate: 89.99,
        price: 30.01,
        currency: 'PHP',
        cryptocurrencyAmount: 34234.15,
        cryptocurrency: 'USDT',
        minLimit: 200000.0,
        maxLimit: 350000.0,
        paymentTimeLimit: 900,
        averageDeliveryTime: 380.4,
        userTradeState: UserTradeStateEnum.Allow,
        terms:
          '购买 USDT请验证\n1.请先加入『azpapa』Line\nID:@ztn9069t，量大可以\n2.银行转帐准备好合法证件,核对身份确保本人自愿交易，请在一张白纸上面写上「今天日期+姓名+本人于弊安交易验证用」字样，搭配一张身分证明文件一起拍照\n3.上传证件&存折封面到Line进行验证\n只收验证过的帐号转入款项。\n最近诈骗手法琳琅满目,请务必小心!\n某人指定你找azpapa买币是诈骗\n网友教你来操作买币是骗\n交友软体教你来买币是骗\n听信网友把货币提现是诈骗\n平台获利却无法提领是诈骗\n平台叫你去汇款缴税是骗\n用大平台才安心\n免责声明:『azpapa』售出放行之usdt属于买家自身财产,所有投资理财请您谨慎评估,并自行负责,若你依然转出交易所遭诈骗报案末与警方清楚交代银货两讫后行为与 卖家无关导致我方银行帐户无辜被冻结,必定提出刑事及民事损害赔偿告诉切勿以不法资金买卖\n交易,违者必究,请勿以身试法。 提醒买家,请具体了解警政署(防骗专线165)所提供之诈骗平台清单,避免将加密货币打入受骗,『azpapa』不为买方之理财行为负责。',
        paymentsList: [
          { id: PaymentEnum.JkoPay, label: '街口转帐', color: 'pink' },
          { id: PaymentEnum.LineBank, label: 'LINE Bank', color: 'green' },
        ],
      },
      {
        tradeType: TradeTypeEnum.Buy,
        nickname: 'G测式单01',
        router: '',
        tradeQty: 61,
        orderRate: 99.99,
        price: 33.6,
        currency: 'PHP',
        cryptocurrencyAmount: 11985.61,
        cryptocurrency: 'USDT',
        minLimit: 300000.0,
        maxLimit: 450000.0,
        paymentTimeLimit: 900,
        averageDeliveryTime: 380.4,
        userTradeState: UserTradeStateEnum.NotAllow,
        terms:
          '购买 USDT请验证\n1.请先加入『azpapa』Line\nID:@ztn9069t，量大可以\n2.银行转帐准备好合法证件,核对身份确保本人自愿交易，请在一张白纸上面写上「今天日期+姓名+本人于弊安交易验证用」字样，搭配一张身分证明文件一起拍照\n3.上传证件&存折封面到Line进行验证\n只收验证过的帐号转入款项。\n最近诈骗手法琳琅满目,请务必小心!\n某人指定你找azpapa买币是诈骗\n网友教你来操作买币是骗\n交友软体教你来买币是骗\n听信网友把货币提现是诈骗\n平台获利却无法提领是诈骗\n平台叫你去汇款缴税是骗\n用大平台才安心\n免责声明:『azpapa』售出放行之usdt属于买家自身财产,所有投资理财请您谨慎评估,并自行负责,若你依然转出交易所遭诈骗报案末与警方清楚交代银货两讫后行为与 卖家无关导致我方银行帐户无辜被冻结,必定提出刑事及民事损害赔偿告诉切勿以不法资金买卖\n交易,违者必究,请勿以身试法。 提醒买家,请具体了解警政署(防骗专线165)所提供之诈骗平台清单,避免将加密货币打入受骗,『azpapa』不为买方之理财行为负责。',
        paymentsList: [
          { id: PaymentEnum.Bank, label: '银行转账', color: 'yellow' },
          { id: PaymentEnum.AliPay, label: 'Alipay', color: 'blue' },
          { id: PaymentEnum.JkoPay, label: '街口转帐', color: 'pink' },
        ],
      },
    ];
    this.transaction$.next(transaction);
  }

  orderCreation(orderCreationData: Maybe<OrderCreationData>) {
    console.log('orderCreation', orderCreationData);
    this.orderCreationData$.next(orderCreationData);
  }
}
