import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService, MessageService, TranslationsService, UserService } from '@lib';
import { SelectLanguageComponent } from '../select-language/select-language.component';
import { filter } from 'rxjs';

enum MenuEnum {
  P2p,
  Order,
  Advertise,
  User,
}

interface MenuList {
  type: MenuEnum;
  label: string;
  icon: string;
  router: string;
}

enum ToolsEnum {
  Language,
  Logout,
}

interface ToolsList {
  type: ToolsEnum;
  label: string;
  icon: string;
}

@Component({
  selector: 'app-nav-top',
  templateUrl: './nav-top.component.html',
  styleUrls: ['./nav-top.component.scss'],
})
export class NavTopComponent implements OnInit {
  langDescription: string | null = null;
  langKey: string;
  isHadToken: boolean = false;
  menuList: MenuList[] = [];
  toolsList: ToolsList[] = [];

  constructor(
    public location: Location,
    public router: Router,
    public translationsSer: TranslationsService,
    public layoutSer: LayoutService,
    private messageSer: MessageService,
    private userSer: UserService
  ) {}

  ngOnInit(): void {
    // logged in so return true
    const currentUser = this.userSer.currentUserToken;
    if (currentUser) this.isHadToken = true;
    this.init();
  }

  init() {
    this.getDefaultLang();
    this.setMenuList();
    this.setToolsList();
  }

  getDefaultLang() {
    this.translationsSer.getSupportedLang();
    this.langKey = this.translationsSer.curLang$.value;
    this.translationsSer.langList.forEach(item => {
      if (item.key === this.langKey) this.langDescription = item.description;
      return;
    });
  }

  async setMenuList() {
    await this.translationsSer
      .get('navUnder')
      .then(data => {
        this.menuList = [
          { type: MenuEnum.P2p, label: data.trade, icon: 'supervisor_account', router: '/trade' }, // P2P
          { type: MenuEnum.Order, label: data.order, icon: 'receipt', router: '/order-history' }, // 订单
          { type: MenuEnum.Advertise, label: data.advertise, icon: 'campaign', router: '/advertise' }, // 广告
          { type: MenuEnum.User, label: data.personal, icon: 'person', router: '/personal' }, // 我的
        ];
      })
      .catch(e => console.log(e));
  }

  async setToolsList() {
    await this.translationsSer
      .get('layout')
      .then(data => {
        this.toolsList = [
          { type: ToolsEnum.Language, label: data.language, icon: 'language' },
          { type: ToolsEnum.Logout, label: data.logout, icon: 'logout' },
        ];
      })
      .catch(e => console.log(e));
  }

  routerLinkActive(routerLink: string): boolean {
    const path = this.location.path();
    switch (routerLink) {
      case '/trade':
        return path.includes('trade') || path.includes('payment-order-creation');
      case '/advertise':
        return (
          path.includes('advertise') || path.includes('add-advertise') || path.includes('advertise-publish-success')
        );
      case '/personal':
        return (
          path.includes('personal') ||
          path.includes('account-management') ||
          path.includes('add-account') ||
          path.includes('addresses-management') ||
          path.includes('add-addresses') ||
          path.includes('recommend-rebate') ||
          path.includes('recharge') ||
          path.includes('withdraw')
        );
      case '/order-history':
        return path.includes('order-history');
      default:
        return false;
    }
  }

  choseTool(type: ToolsEnum): void {
    let params = {};
    switch (type) {
      case ToolsEnum.Language:
        params = { langKey: this.langKey };
        this.openDialog(params, type);
        break;
      case ToolsEnum.Logout:
        this.logout();
        break;
      default:
        return;
    }
  }

  openDialog(params?: Record<string, never>, type?: ToolsEnum): void {
    let component;
    const disableClose = false;
    switch (type) {
      case ToolsEnum.Language:
        component = SelectLanguageComponent;
        break;
      default:
        return;
    }

    this.messageSer.dialog$.next({
      component: component,
      config: {
        maxWidth: '420px',
        minWidth: '420px',
        maxHeight: '90vh',
        disableClose: disableClose,
        data: {
          ...params,
        },
      },
      callback: ref => {
        ref
          .afterClosed()
          .pipe(filter(confirm => !!confirm))
          .subscribe({
            next: resp => {
              switch (type) {
                case ToolsEnum.Language:
                  this.langDescription = resp.description;
                  this.langKey = resp.key;
                  break;
                default:
                  return;
              }
              this.reset();
            },
          });
      },
    });
  }

  reset() {
    console.log('close');
  }

  logout() {
    this.userSer.logout();
  }
}
