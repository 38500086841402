<div class="footer">
  <ng-container *ngFor="let item of footerLinkList">
    <div *ngIf="item.id === footerLink.Language" class="footer-link flex items-center gap-1" (click)="selectLanguage()">
      <mat-icon class="icon">language</mat-icon>{{ item.label }}
    </div>
    <div *ngIf="item.id !== footerLink.Language" class="footer-link" (click)="routerLinkActive(item.router)">
      {{ item.label }}
    </div>
  </ng-container>
</div>
