import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface AddressesManagement {
  id: number;
  cryptocurrency: string;
  name: string;
  address: string;
  isSwipeLeft: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AddressesManagementService {
  addressesManagement$ = new BehaviorSubject<AddressesManagement[]>([]);

  constructor() {
    this.getAddressesManagement();
  }

  getAddressesManagement() {
    const addressesManagement = [
      {
        id: 0,
        cryptocurrency: 'USDT',
        name: 'Tron(TRC20)',
        address: 'TRv94BnRzdxXzXc4RrXVtD6tKm6Lcz7M4E',
        isSwipeLeft: false,
      },
      {
        id: 1,
        cryptocurrency: 'USDT',
        name: 'Tron(TRC20)',
        address: 'TFYNy5zP79Fg3Coc5k2AJcaCaaRitS6AKL',
        isSwipeLeft: false,
      },
      {
        id: 2,
        cryptocurrency: 'USDT',
        name: 'Tron(TRC20)',
        address: 'TKxe5SzRzdaAzBc9TtUVJd8MKn0Lew9Z7C',
        isSwipeLeft: false,
      },
      {
        id: 3,
        cryptocurrency: 'USDT',
        name: 'Tron(TRC20)',
        address: 'TRv94BnRzdxXzXc4RrXVtD6tKm6Lcz7M4E',
        isSwipeLeft: false,
      },
      {
        id: 4,
        cryptocurrency: 'USDT',
        name: 'Tron(TRC20)',
        address: 'TFYNy5zP79Fg3Coc5k2AJcaCaaRitS6AKL',
        isSwipeLeft: false,
      },
      {
        id: 5,
        cryptocurrency: 'USDT',
        name: 'Tron(TRC20)',
        address: 'TKxe5SzRzdaAzBc9TtUVJd8MKn0Lew9Z7C',
        isSwipeLeft: false,
      },
    ];
    this.addressesManagement$.next(addressesManagement);
  }

  deleteAddresses(id: number) {
    const filterData = this.addressesManagement$.value.filter(d => d.id !== id);
    this.addressesManagement$.next(filterData);
  }
}
