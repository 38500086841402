<div class="login-authenticator">
  <div class="dialog-nav">
    <div class="dialog-title">{{ translationsSer.loginAuthenticator.title | translate }}</div>
    <button class="dialog-close-btn" (click)="close()">
      <mat-icon class="dialog-close-icon">close</mat-icon>
    </button>
  </div>
  <div class="dialog-content">
    <form
      class="form-deep"
      [formGroup]="inputForm"
      (ngSubmit)="inputForm.valid && onSubmit()"
      (keydown.enter)="inputKeyup($event)">
      <div
        class="input-stroked-deep input-form"
        [class.selected]="isAuthCodeFocus"
        [class.error]="isError(inputType.AuthCode)">
        <label for="authCode" [class.fill]="isAuthCodeFill">{{ translationsSer.inputText.authCode | translate }}</label>
        <div class="input-block">
          <input
            type="password"
            [(ngModel)]="authCode"
            id="authCode"
            formControlName="authCode"
            [placeholder]="authCodePlaceholder"
            (focus)="inputFocus(inputType.AuthCode)"
            (blur)="inputBlur(inputType.AuthCode)"
            (input)="input(inputType.AuthCode)" />
        </div>
        <!-- msg -->
        <div *ngIf="isError(inputType.AuthCode)" class="msg">
          <div *ngIf="inputForm.hasError('required', 'authCode')">
            {{ translationsSer.inputText.authCodeRequired | translate }}
          </div>
          <div *ngIf="inputForm.hasError('pattern', 'authCode')">{{ 'error' }}</div>
        </div>
      </div>
      <div class="dialog-button">
        <button mat-flat-button type="reset" class="btn-flat-gray w-1/2 h-10" (click)="close()">
          {{ translationsSer.layout.cancel | translate }}
        </button>
        <button
          mat-flat-button
          type="submit"
          class="w-1/2 h-10"
          [class]="onSubmitStyle"
          (click)="onSubmit()"
          [disabled]="inputForm.invalid">
          {{ translationsSer.layout.confirm | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
