import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvatarFormatPipe } from './avatar-format.pipe';
import { ExpireTimeFormatPipe } from './expire-time-format.pipe';
import { MoneyFormatPipe } from './money-format.pipe';
import { MinutesDecimalFormatPipe } from './minutes-decimal-format.pipe';
import { ReplaceWithXPipe } from './replace-with-x.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    MoneyFormatPipe,
    AvatarFormatPipe,
    MinutesDecimalFormatPipe,
    ExpireTimeFormatPipe,
    ReplaceWithXPipe,
  ],
  imports: [CommonModule],
  exports: [
    SafeHtmlPipe,
    MoneyFormatPipe,
    AvatarFormatPipe,
    MinutesDecimalFormatPipe,
    ExpireTimeFormatPipe,
    ReplaceWithXPipe,
  ],
})
export class PipesModule {}
