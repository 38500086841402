import { Injectable } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

export interface RouteData extends Data {
  nav?: boolean;
  title?: string;
  class?: string;
  back?: boolean;
  backPersonal?: boolean;
  navUnder?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  nav$ = new BehaviorSubject<boolean>(false);
  title$ = new BehaviorSubject<string>('');
  back$ = new BehaviorSubject<boolean>(false);
  backPersonal$ = new BehaviorSubject<boolean>(false);
  navUnder$ = new BehaviorSubject<boolean>(false);

  constructor(router: Router, route: ActivatedRoute) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        const path = router.url.substring(1);
        const currRoute = this.getCurrentPathRoute(path, route.root);
        this.nav$.next(currRoute['nav'] || false);
        this.title$.next(currRoute['title'] || '');
        this.back$.next(currRoute['back'] || false);
        this.backPersonal$.next(currRoute['backPersonal'] || false);
        this.navUnder$.next(currRoute['navUnder'] || false);
      }
    });
  }

  // 尾遞迴找出當前路由的路由配置
  getCurrentPathRoute(path: string, route: ActivatedRoute): Data {
    if (route.firstChild) {
      return this.getCurrentPathRoute(path, route.firstChild);
    } else {
      return { showBottom: false, ...(route.snapshot.data || {}) };
    }
  }
}
