import Decimal, { Numeric } from 'decimal.js-light';

export class Utils {
  static moneyStringToNumber(value: string | number = '') {
    if (typeof value === 'number') {
      return value;
    }
    return Number(value.split(',').join('')) || 0;
  }

  static moneyFormat(value: number | string, needComma = true, digit = 3) {
    if (!value) return value;
    const money = this.moneyStringToNumber(value);
    const formatedMoney = new Decimal(money).toDecimalPlaces(digit, Decimal.ROUND_DOWN).toNumber();
    if (needComma) {
      return this.thousandSeprator(formatedMoney);
    }
    return formatedMoney;
  }

  static thousandSeprator(num: number): string {
    const numParts = new Decimal(num).toFixed(2, Decimal.ROUND_DOWN).toString().split('.');
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return numParts.join('.');
  }

  // 取小数点精度计算
  static roundPrecise(value: Numeric, precision: number = 3) {
    return new Decimal(value).toDecimalPlaces(precision).toNumber();
  }

  static roundFloor(value: Numeric, decimal: number = 2) {
    return parseFloat(new Decimal(value).toFixed(decimal, Decimal.ROUND_DOWN));
  }

  // 取广告名称第一个字
  static avatarFormat(value: string) {
    if (value && value.length > 0) return value[0];
    return value;
  }

  static minutesDecimalFormat(value: number, decimal: number = 2) {
    const mins = parseFloat(new Decimal((value % 3600) / 60).toFixed(decimal, Decimal.ROUND_DOWN));
    return `${mins}`;
  }

  static expireTimeFormat(value: number, isTextFormat: boolean): string {
    const hours = Math.floor(value / 3600)
      .toString()
      .padStart(2, '0');
    const hoursText = isTextFormat ? ' 时 ' : ':';
    const mins = Math.floor((value % 3600) / 60)
      .toString()
      .padStart(2, '0');
    const minsText = isTextFormat ? ' 分 ' : ':';
    const secs = ((value % 3600) % 60).toString().padStart(2, '0');
    const secsText = isTextFormat ? ' 秒' : '';
    if (value > 3599) return `${hours}${hoursText}${mins}${minsText}${secs}${secsText}`;
    return `${mins}${minsText}${secs}${secsText}`;
  }

  // 字符串替換為'*'
  static replaceWithX(value: string, startChar: number, endChar: number): string {
    if (value.length > endChar) {
      for (let i = startChar; i < endChar; i++) {
        value = value.replace(value[i], '*');
      }
      return value;
    }
    return value;
  }
}
