import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum TradeTypeEnum {
  All,
  Buy,
  Sell,
}

export enum CryptocurrencyEnum {
  All,
  USDT,
  BTC,
  FDUSD,
  ETH,
}

export interface Cryptocurrency {
  id: CryptocurrencyEnum;
  label: string;
}

export enum CurrencyEnum {
  All,
  PHP,
  CNY,
  TWD,
  USD,
}

export interface Currency {
  id: CurrencyEnum;
  label: string;
}

export enum AccountTypeEnum {
  BankCard,
  AliPay,
  JkoPay,
  LineBank,
  LinePay,
}

export interface AccountTypes {
  id: AccountTypeEnum;
  label: string;
}

export enum PaymentEnum {
  All,
  Bank,
  AliPay,
  JkoPay,
  LineBank,
  LinePay,
}

export interface Payment {
  id: PaymentEnum;
  label: string;
  color: string;
}

export enum MainnetEnum {
  Tron,
  GEO,
  Settle,
  P2PExpress,
}

export interface Mainnet {
  id: MainnetEnum;
  label: string;
}

@Injectable({
  providedIn: 'root',
})
export class CategoryOptionsService {
  cryptocurrency$ = new BehaviorSubject<Cryptocurrency[]>([]);
  cryptocurrencyDefault$ = new BehaviorSubject<Cryptocurrency>(<Cryptocurrency>{});

  currency$ = new BehaviorSubject<Currency[]>([]);
  currencyDefault$ = new BehaviorSubject<Currency>(<Currency>{});

  accountTypes$ = new BehaviorSubject<AccountTypes[]>([]);
  accountTypeDefault$ = new BehaviorSubject<AccountTypes>(<AccountTypes>{});

  payments$ = new BehaviorSubject<Payment[]>([]);
  paymentDefault$ = new BehaviorSubject<Payment>(<Payment>{});

  mainnet$ = new BehaviorSubject<Mainnet[]>([]);
  mainnetDefault$ = new BehaviorSubject<Mainnet>(<Mainnet>{});

  constructor() {
    this.init();
  }

  init() {
    this.getCryptocurrency();
    this.getCurrency();
    this.getAccountPayments();
    this.getPayments();
    this.getMainnet();
  }

  getCryptocurrency() {
    const cryptocurrency = [
      { id: CryptocurrencyEnum.All, label: '加密货币' },
      { id: CryptocurrencyEnum.USDT, label: 'USDT' },
      { id: CryptocurrencyEnum.BTC, label: 'BTC' },
      { id: CryptocurrencyEnum.FDUSD, label: 'FDUSD' },
      { id: CryptocurrencyEnum.ETH, label: 'ETH' },
    ];
    this.cryptocurrency$.next(cryptocurrency);
    this.cryptocurrencyDefault$.next(cryptocurrency[0]);
  }

  getCurrency() {
    const currency = [
      { id: CurrencyEnum.All, label: '法币' },
      { id: CurrencyEnum.PHP, label: 'PHP' },
      { id: CurrencyEnum.CNY, label: 'CNY' },
      { id: CurrencyEnum.TWD, label: 'TWD' },
      { id: CurrencyEnum.USD, label: 'USD' },
    ];
    this.currency$.next(currency);
    this.currencyDefault$.next(currency[0]);
  }

  getAccountPayments() {
    const accountTypes = [
      { id: AccountTypeEnum.BankCard, label: '银行卡' },
      { id: AccountTypeEnum.AliPay, label: 'Alipay' },
      { id: AccountTypeEnum.JkoPay, label: '街口支付' },
      { id: AccountTypeEnum.LineBank, label: 'LINE Bank' },
      { id: AccountTypeEnum.LinePay, label: 'LINE Pay' },
    ];
    this.accountTypes$.next(accountTypes);
    this.accountTypeDefault$.next(accountTypes[0]);
  }

  getPayments() {
    const payments = [
      { id: PaymentEnum.All, label: '所有支付', color: '' },
      { id: PaymentEnum.Bank, label: '银行转账', color: 'yellow' },
      { id: PaymentEnum.AliPay, label: 'Alipay', color: 'blue' },
      { id: PaymentEnum.JkoPay, label: '街口转帐', color: 'pink' },
      { id: PaymentEnum.LineBank, label: 'LINE Bank', color: 'green' },
      { id: PaymentEnum.LinePay, label: 'LINE Pay', color: 'green' },
    ];
    this.payments$.next(payments);
    this.paymentDefault$.next(payments[0]);
  }

  getMainnet() {
    const mainnet = [
      { id: MainnetEnum.Tron, label: 'Tron(TRC20)' },
      { id: MainnetEnum.GEO, label: 'GEO Pay Wallet' },
      { id: MainnetEnum.Settle, label: 'SettlePay Wallet' },
      { id: MainnetEnum.P2PExpress, label: 'P2P Express' },
    ];
    this.mainnet$.next(mainnet);
    this.mainnetDefault$.next(mainnet[0]);
  }
}
