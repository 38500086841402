import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageKey, StorageService } from '../core';

interface LoginForm {
  account: string;
  password: string;
}

interface LoginAuthCodeForm {
  authCode?: string;
}

interface RegisterForm {
  account: string;
  password: string;
  confirmPassword: string;
  invitationCode: string;
}

export interface User {
  nickName: string;
  account: string;
  walletbalance: number;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private currentUserSubject$: BehaviorSubject<string | null | undefined>;
  user$ = new BehaviorSubject<User>(<User>{});

  constructor(private storageSer: StorageService) {
    this.currentUserSubject$ = new BehaviorSubject<string | null | undefined>(
      this.storageSer.getItem(LocalStorageKey.Token)
    );
    this.init();
  }

  public get currentUserToken(): string | null | undefined {
    return this.currentUserSubject$.value;
  }

  init() {
    if (this.currentUserSubject$.value) this.user();
  }

  login(loginForm: LoginForm) {
    return new Promise((resolve, reject) => {
      if (!loginForm.account || !loginForm.password) reject({ code: 202, msg: 'error', data: {} });

      const token = '1880|V5MzVSG7fssZabQEqFnQZf0MI0ljZHJvDpwpFcS3';
      this.storageSer.setItem<string>(LocalStorageKey.Token, token!);
      this.currentUserSubject$.next(token || null);

      resolve({ code: 200, msg: 'success', data: { isShowAuthCode: true } });
    }).catch(error => {
      return error;
    });
  }

  loginAuthCode(loginAuthCodeForm: LoginAuthCodeForm) {
    return new Promise((resolve, reject) => {
      if (!loginAuthCodeForm.authCode) {
        reject({ code: 202, msg: 'error' });
        return;
      }
      this.user();
      resolve({ code: 200, msg: 'success' });
    }).catch(error => {
      return error;
    });
  }

  register(registerForm: RegisterForm) {
    return new Promise((resolve, reject) => {
      if (
        !registerForm.account ||
        !registerForm.password ||
        !registerForm.confirmPassword ||
        !registerForm.invitationCode
      ) {
        reject({ code: 202, msg: '创建失败' });
      }
      resolve({ code: 200, msg: 'success' });
    }).catch(error => {
      return error;
    });
  }

  async logout() {
    // remove user from local storage to log user out
    this.storageSer.removeItem(LocalStorageKey.Token);
    (window as Window).location = '/login';
  }

  user() {
    const user = {
      nickName: "User's Nick Name",
      account: 'Luean',
      walletbalance: 201.47,
    };
    this.user$.next(user);
  }
}
