import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TradeTypeEnum } from './category-options.service';

export enum PayStateEnum {
  Pending,
  Paid,
}

export interface OrderHistory {
  nickname: string;
  tradeType: TradeTypeEnum;
  currency: string;
  cryptocurrency: string;
  payState: PayStateEnum;
  expireTime: number;
  amount: number | string;
  unitprice: number | string;
  quantity: number | string;
  orderId: string;
  createdAt: number | string;
}

@Injectable({
  providedIn: 'root',
})
export class OrderHistoryService {
  orderHistory$ = new BehaviorSubject<OrderHistory[]>([]);

  constructor() {
    this.getOrderHistory();
  }

  getOrderHistory() {
    const orderHistory = [
      {
        nickname: 'A测式单01',
        tradeType: TradeTypeEnum.Buy,
        currency: 'PHP',
        cryptocurrency: 'USDT',
        payState: PayStateEnum.Pending,
        expireTime: 1799,
        amount: 5000,
        unitprice: 32.61,
        quantity: 155.27,
        orderId: '3304ba14-dd53-409c-b534-b5783483793e',
        createdAt: 1715767464000,
      },
      {
        nickname: 'B测式单01',
        tradeType: TradeTypeEnum.Buy,
        currency: 'PHP',
        cryptocurrency: 'USDT',
        payState: PayStateEnum.Pending,
        expireTime: 999,
        amount: 3000,
        unitprice: 33.17,
        quantity: 160.33,
        orderId: '7784zw152-dd53-409c-b534-b5783483793e',
        createdAt: '2024/05/07 09:47:01',
      },
      {
        nickname: 'C测式单01',
        tradeType: TradeTypeEnum.Sell,
        currency: 'PHP',
        cryptocurrency: 'USDT',
        payState: PayStateEnum.Pending,
        expireTime: 1200,
        amount: 2000,
        unitprice: 32.21,
        quantity: 267.27,
        orderId: 'fc4ac5e5-d72e-49f6-a149-5771d7dfee17',
        createdAt: '2024/05/05 19:10:31',
      },
      {
        nickname: 'D测式单01',
        tradeType: TradeTypeEnum.Buy,
        currency: 'PHP',
        cryptocurrency: 'USDT',
        payState: PayStateEnum.Paid,
        expireTime: 0,
        amount: 3000,
        unitprice: 33.17,
        quantity: 160.33,
        orderId: '7784zw152-dd53-409c-b534-b5783483793e',
        createdAt: '2024/05/07 09:47:01',
      },
      {
        nickname: 'E测式单01',
        tradeType: TradeTypeEnum.Sell,
        currency: 'CNY',
        cryptocurrency: 'BTC',
        payState: PayStateEnum.Paid,
        expireTime: 0,
        amount: 3000,
        unitprice: 32.21,
        quantity: 267.27,
        orderId: 'fc4ac5e5-d72e-49f6-a149-5771d7dfee17',
        createdAt: '2024/05/03 17:01:28',
      },
      {
        nickname: 'F测式单01',
        tradeType: TradeTypeEnum.Buy,
        currency: 'PHP',
        cryptocurrency: 'USDT',
        payState: PayStateEnum.Pending,
        expireTime: 999,
        amount: 3000,
        unitprice: 33.17,
        quantity: 160.33,
        orderId: '7784zw152-dd53-409c-b534-b5783483793e',
        createdAt: '2024/05/07 09:47:01',
      },
      {
        nickname: 'G测式单01',
        tradeType: TradeTypeEnum.Sell,
        currency: 'PHP',
        cryptocurrency: 'USDT',
        payState: PayStateEnum.Pending,
        expireTime: 1200,
        amount: 2000,
        unitprice: 32.21,
        quantity: 267.27,
        orderId: 'fc4ac5e5-d72e-49f6-a149-5771d7dfee17',
        createdAt: '2024/05/05 19:10:31',
      },
    ];
    this.orderHistory$.next(orderHistory);
  }
}
