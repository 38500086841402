export default {
  layout: {
    noData: '尚无资料',
    noOrder: '尚无订单',
    noAd: '暂无广告',
    cancel: '取消',
    confirm: '确定',
    publish: '发布',
    confirmPublish: '确认发布',
    revoke: '撤销',
    finish: '完成',
    save: '保存',
    bind: '绑定',
    recharge: '充值',
    withdraw: '提现',
    confirmPayment: '确认付款',
    logout: '登出',
    language: '语言',
    operate: '操作',
    add: '添加',
    edit: '编辑',
    delete: '删除',
    confirmDeleteText: '请再次确认是否删除！',
    filter: '筛选',
    reset: '重置',
    nextStep: '下一步',
    previousStep: '上一步',
  },
  inputText: {
    account: '账号',
    accountPlaceholder: '请输入账号',
    accountRequired: '账号不得为空',
    password: '密码',
    passwordPlaceholder: '请输入密码',
    passwordRequired: '密码不得为空',
    confirmPassword: '再次确认密码',
    confirmPasswordPlaceholder: '请输入确认密码',
    confirmPasswordRequired: '确认密码不得为空',
    oldPassword: '旧密码',
    oldPasswordPlaceholder: '请输入旧密码',
    oldPasswordRequired: '旧密码不得为空',
    newPassword: '新密码',
    newPasswordPlaceholder: '请输入新密码',
    newPasswordRequired: '新密码不得为空',
    confirmNewPassword: '再次确认新密码',
    confirmNewPasswordPlaceholder: '请输入确认新密码',
    confirmNewPasswordRequired: '确认新密码不得为空',
    nickName: '昵称',
    nickNamePlaceholder: '请输入昵称',
    nickNameRequired: '昵称不得为空',
    secret: '密钥',
    secretPlaceholder: '请输入密钥',
    secretRequired: '密钥不得为空',
    authCode: '谷歌动态验证码',
    authCodePlaceholder: '请输入谷歌动态验证码',
    authCodeRequired: '验证码不得为空',
    invitationCode: '邀请码',
    invitationCodePlaceholder: '请输入邀请码',
    invitationCodeRequired: '邀请码不得为空',
    price: '价格',
    pricePlaceholder: '请输入价格',
    priceRequired: '价格不得为空',
    pricePattern: '价格须大于 0',
    quantity: '交易数量',
    quantityPlaceholder: '请输入交易数量',
    quantityRequired: '交易数量不得为空',
    quantityPattern: '交易数量须大于 0',
    minLimit: '单笔订单限额(最小)',
    minLimitPlaceholder: '请输入单笔订单限额(最小)',
    minLimitRequired: '单笔订单限额(最小)不得為空',
    minLimitPattern: '单笔订单最小限额须大于 0',
    minLimitMaxlength: '不得大于单笔订单最大限额',
    maxLimit: '单笔订单限额(最大)',
    maxLimitPlaceholder: '请输入单笔订单限额(最大)',
    maxLimitRequired: '单笔订单限额(最大)不得為空',
    maxLimitPattern: '单笔订单最大限额须大于 0',
    maxLimitMinlength: '不得小于单笔订单最小限额',
    terms: '交易条款(选填)',
    termsPlaceholder: '交易条款将在用户下单前展示',
    termsRequired: '交易数量不得为空',
    buyRate: '买入费率',
    buyRatePlaceholder: '请输入买入费率',
    buyRateRequired: '买入费率不得为空',
    sellRate: '卖出费率',
    sellRatePlaceholder: '请输入卖出费率',
    sellRateRequired: '卖出费率不得为空',
    rechargeAmount: '充值金额',
    rechargeAmountPlaceholder: '请输入充值金额',
    rechargeAmountRequired: '充值金额不得为空',
    withdrawAmount: '提领金额',
    withdrawAmountPlaceholder: '请输入提领金额',
    withdrawAmountRequired: '提领金额不得为空',
    addresses: '地址',
    addressesPlaceholder: '请输入地址或长按贴上',
    addressesRequired: '地址不得为空',
    languagePlaceholder: '语言',
    cryptocurrencyPlaceholder: '币种',
    currencyPlaceholder: '币种',
    accountTypePlaceholder: '账户类型',
    paymentPlaceholder: '付款方式',
    mainnetPlaceholder: '主网',
  },
  navTop: {
    login: '登录',
    register: '注册',
  },
  navUnder: {
    trade: 'P2P',
    order: '订单',
    advertise: '广告',
    personal: '我的',
  },
  footer: {
    terms: '条款',
    privacyStatement: '隐私说明',
  },
  login: {
    title: '登录',
    goToRegister: '前往创建账号',
  },
  loginAuthenticator: {
    title: '输入谷歌验证码',
  },
  register: {
    title: '创建个人账户',
    goToLogin: '已有账号，前往登录',
  },
  selectLanguage: {
    title: '选择语言',
  },
  selectCryptocurrency: {
    title: '选择币种',
  },
  selectCurrency: {
    title: '选择币种',
  },
  selectAccountType: {
    title: '选择账户类型',
  },
  selectPayments: {
    title: '选择付款方式',
  },
  selectMainnet: {
    title: '选择主网',
  },
  selectAdvertise: {
    title: '广告筛选',
  },
  sortTransaction: {
    price: '价格',
    tradeQty: '交易数',
    orderRate: '成单率',
  },
  trade: {
    quick: '快捷区',
    selfSelected: '自选区',
    bulk: '大宗',
  },
  allPayments: {
    buy: '购买',
    sell: '出售',
    cryptocurrency: '加密货币',
    currency: '法币',
    paymentTypes: '支付方式',
    sort: '排序',
    tradeQty: '成交量',
    orderRate: '成交率',
    quantity: '数量',
    limit: '限额',
    restricted: '受限制',
  },
  paymentDetail: {
    buy: '购买',
    sell: '出售',
    price: '价格',
    wantPay: '我要支付',
    wantPayAll: '全部',
    amountRequired: '不得为空',
    amountPattern: '订单限额：',
    willReceive: '我将收到',
    cryptocurrencyAmountRequired: '不得为空',
    paymentType: '支付方式',
    selectPaymentType: '请选择支付方式',
    tradeQty: '成交量',
    orderRate: '成交率',
    timeliness: '支付时效',
    releaseTime: '平均放行时间',
    minutes: '分钟',
    cryptocurrencyAmount: '数量',
    adTerms: '广告商条款 (请仔细阅读)',
  },
  paymentOrderCreation: {
    title: '订单详情',
    stepLabel_1: '订单已生成',
    stepLabel_2: '付款予卖方',
    stepLabel_3: '放行加密货币',
    buy: '购买',
    sell: '出售',
    legalCurrencyAmount: '法币金额',
    price: '价格',
    receiveQuantity: '接收数量',
    orderNumber: '订单编号',
    creationTime: '创建时间',
    sellerNickname: '卖家昵称',
    paymentType: '支付方式',
    cancelOrder: '取消订单',
    goToPay: '去付款',
    help: '帮助',
    havePaid: '我已付款，通知卖方',
    appeal: '申诉',
    step_1_topNotes: '点击下方「付款」按钮，查看付款详情',
    step_1_title: '订单已生成',
    step_1_timeliness: '请在 {{ timeliness }} 内付款给卖家',
    step_1_midNotes: '卖方的加密货币由币安托管。',
    step_1_errorNotes: '请仅使用您自已的付款账户将次金转账给卖方。 禁止第三方支付。',
    step_2_topNotes: '继续付款，然后点选「我已付款」',
    step_2_title: '付款予卖方',
    step_2_timeliness: '订单将在 {{ timeliness }} 后取消',
    step_2_tab_1: '付款',
    step_2_tab_2: '提示',
    step_2_payTitle_1: '透过 {{ payment }} 转账 {{ payAmount }} {{ currency }}',
    step_2_payInfoLabel_1: '您支付',
    step_2_payInfoLabel_2: '姓名',
    step_2_payInfoLabel_3: '账号',
    step_2_payTitle_2_1: '确认付款成功，然后',
    step_2_payTitle_2_2: '点击下方按钮',
    step_2_payTitle_2_3: '通知卖家。',
    step_2_orderInfoNotes: '* 使用您自己的付款账户，并确保账户上的姓名与您用于验证币安账户的姓名相符。',
    step_3_topNotes: '请等待对方放行款项',
    step_3_title: '放行中',
    step_3_service: 'P2P客服',
    adTerms: '广告商条款 (请仔细阅读)',
  },
  paymentOrderClose: {
    title: '离开页面？',
    contentText: '如果您已付款，请务必点击「已付款，通知卖方」按钮。',
    timeLeft: '付款剩余时间',
    checkText: '我确认还没付款给对方',
    checkRequired: '请先勾选我确认还没付款给对方',
  },
  paymentOrderCheck: {
    title: '付款确认',
    contentText: '付款时，您必须离开币安平台并自行完成转账。 币安将不会为您自动转账。',
    haveTransferred: '我已转账',
    notTransferredYet: '我尚未转账',
  },
  paymentProof: {
    title: '付款确认',
    contentTitle_1: '1. 上传付款证明',
    contentInfo_1_1: '上传并向卖方发送至少1份（最多3份）付款证明。',
    contentInfo_1_2: '上传档案格式必须为 .jpg、.jpeg 或 .png。',
    upload: '上传',
    contentTitle_2: '2. 确认付款',
    contentInfo_2_1: '您必须自行透过币安以外的平台进行转账。',
    contentInfo_2_2:
      '未付款前，请勿点击「已转账」按钮。 请勿进行第三方付款。 如有违反，可能导致账户遭到停权，平台亦可能向您请求损害赔偿。',
    checkText: '我已阅读并了解上述资讯。',
    checkRequired: '请先勾选我已阅读并了解上述资讯。',
  },
  orderHistory: {
    title: '历史订单',
    tabLabel_1: '进行中',
    tabLabel_2: '已完成',
    buy: '购买',
    sell: '出售',
    pendingPayment: '待付款',
    paid: '已付款',
    types: '类型',
    cryptocurrency: '加密货币',
    orderId: '订单',
    totalAmount: '总额',
    price: '价格',
    quantity: '数量',
    states: '状态',
    nickname: '对方昵称',
  },
  advertise: {
    title: '我的广告',
    advertiseId: '广告编号',
    cryptocurrency: '加密货币',
    currency: '法币',
    tradedQty: '已成交交易数量',
    price: '价格',
    paymentTypes: '支付方式',
    types: '类型',
    allTradeTypes: '所有类型',
    states: '状态',
    allAdStates: '所有状态',
    buy: '购买',
    sell: '出售',
    with: '藉由',
    ads: '{{ ads }} 则广告',
    online: '已上架',
    offline: '已下架',
    allOnline: '全部上架',
    allOffline: '全部下架',
    quantity: '数量',
    limit: '限额',
    placeAnAd: '发布广告',
    createdAt: '创建时间',
    updatedAt: '更新时间',
    offlineAdText: '请先下架广告',
    addAdText: '新增广告',
    deleteAdText: '删除广告',
  },
  addAdvertise: {
    title: '发布广告',
    stepLabel_1: '设定类型和价格',
    stepLabel_2: '设定金额和支付',
    stepLabel_3: '设定条件',
    step_1_label_1: '我想要',
    buy: '购买',
    sell: '出售',
    step_1_label_2: '币种',
    step_1_label_3: '用法币',
    paymentType: '付款方式',
    selectLimit: '最多选择 {{ selectLimit }} 个',
    expireTime: '支付时效',
    minutes: '分钟',
    online: '立即上线',
    manualOnline: '稍后手动上线',
  },
  advertiseConfirmPublish: {
    title: '确认发布',
    types: '类型',
    buy: '购买',
    sell: '出售',
    cryptocurrency: '加密货币',
    currency: '法币',
    price: '价格',
    orderLimit: '订单限额',
    cryptocurrencyAmount: '交易数量',
    paymentType: '支付方式',
    expireTime: '支付时效',
    minutes: '分钟',
  },
  editAdvertise: {
    title: '编辑广告',
    paymentType: '付款方式',
    selectLimit: '最多选择 {{ selectLimit }} 个',
    expireTime: '支付时效',
    minutes: '分钟',
  },
  advertisePublishSuccess: {
    title: '广告发布成功',
    titleText: '发布成功，您的广告上架后将被其他用户下单。请注意新订单提醒',
    advertiseNumber: '广告编号',
    buy: '购买',
    sell: '出售',
    with: '藉由',
    quantity: '交易数量',
    limit: '单笔限额',
  },
  personal: {
    title: '帐户资讯',
    account: '账号',
    walletbalance: '钱包余额',
    infoLabel: '注册资讯',
    settingLabel_1: '登入密码',
    settingLabel_2: '谷歌验证器',
    settingLabel_3: '账户管理',
    settingLabel_4: '加密货币地址',
    settingLabel_5: '推荐返佣',
    settingLabel_6: '充值',
    settingLabel_7: '提现',
    verified: '已验证',
    unverified: '未验证',
    link: '连结',
    unlink: '解除连结',
  },
  editNickName: {
    title: '编辑昵称',
    contentText: '为您的个人档案设定自订昵称。不建议使用真实名称或社群账户名称。',
  },
  editPassword: {
    title: '变更密码',
    contentText: '变更您的个人密码。不建议使用生日或简易数字(例：000000、123456)。',
  },
  authenticator: {
    title: '绑定谷歌验证器',
    copyKey: '复制密钥',
    contentInfo_1: '谷歌验证器下载',
    downloadAndroid: '[Android下载]',
    downloadIOS: '[IOS下载]',
    contentInfo_2: '1. 下载后打开谷歌验证器，点右上的『＋』-> 选择【扫描条形码】 或【输入提供的密钥】并贴上密钥',
    contentInfo_3: '2. 复制6位动态码回到app贴上绑定',
  },
  accountManagement: {
    title: '编辑账户',
    addAccountText: '新增账户',
    deleteAccountText: '删除账户',
  },
  addAccount: {
    title: '新增账户',
    currency: '币种',
    accountType: '账户类型',
  },
  addressesManagement: {
    title: '编辑地址',
    addAddressesText: '新增地址',
    deleteAddressesText: '删除地址',
  },
  addAddresses: {
    title: '新增地址',
    cryptocurrency: '加密货币币种',
    mainnetType: '主网类型',
    selectMainnet: '请选择主网',
  },
  recommendRebate: {
    title: '邀请返佣',
    createMember: '创建下级',
    createRecommend: '创建推荐',
    tabLabel_1: '奖励',
    tabLabel_2: '推荐名单',
    tabLabel_3: '推荐注册',
    account: '账号',
    quantity: '交易数量',
    buyRate: '买入费率',
    sellRate: '卖出费率',
    totalCost: '总计',
    registerTime: '注册时间',
    createdTime: '建立时间',
    inviteCode: '邀请码',
    inviteLink: '邀请连结',
    inviteFriends: '邀请好友',
  },
  modifyExchangeRate: {
    title: '编辑汇率',
    account: '账号：',
    tradeSettings: '交易设置',
  },
  createRecommendRebate: {
    title: '创建推荐邀请码',
    tradeSettings: '交易设置',
  },
  createMember: {
    title: '创建下级会员',
  },
  recharge: {
    title: '充值',
    available: '可用',
    noteText_1: '* 请勿直接提现至众筹ICO地址，我们不会处理未来代币的发放。',
    noteText_2: '* 请勿与受制裁企业进行交易。',
    more: '了解更多',
  },
  withdraw: {
    title: '提现',
    mainnetType: '主网类型',
    selectMainnet: '请选择主网',
    withdrawAll: '全部提现',
    available: '可用',
    noteText_1: '* 请勿直接提现至众筹ICO地址，我们不会处理未来代币的发放。',
    noteText_2: '* 请勿与受制裁企业进行交易。',
    more: '了解更多',
    receivedAmount: '收款金额',
    networkCharges: '网路费用',
  },
};
