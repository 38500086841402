import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService, TranslationsService, UserService } from '@lib';
import { filter } from 'rxjs';
import { LoginAuthenticatorComponent } from './login-authenticator/login-authenticator.component';

export enum InputTypeEnum {
  Account = 'account',
  Password = 'password',
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  inputType = InputTypeEnum;
  loginForm!: FormGroup;

  account: string | null = null;
  isAccountFocus = false;
  isAccountFill = false;
  accountPlaceholder: string | null = null;

  password: string | null = null;
  isPasswordFocus = false;
  isPasswordFill = false;
  passwordPlaceholder: string | null = null;

  get onSubmitStyle(): string {
    if (this.loginForm.invalid) return 'btn-flat-disabled-gray';
    return 'btn-flat-yellow';
  }

  constructor(
    public router: Router,
    public translationsSer: TranslationsService,
    private messageSer: MessageService,
    private formBuilder: FormBuilder,
    private userSer: UserService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      account: ['', [this.accountValidator]],
      password: ['', [this.passwordValidator]],
    });
    this.setPlaceholder();
  }

  async setPlaceholder() {
    await this.translationsSer
      .get('inputText')
      .then(data => {
        // 请输入账号
        this.accountPlaceholder = data.accountPlaceholder;
        // 请输入密码
        this.passwordPlaceholder = data.passwordPlaceholder;
      })
      .catch(e => console.log(e));
  }

  accountValidator = (control: FormControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value === null) {
      return { error: true, pattern: true };
    }
    return {};
  };

  passwordValidator = (control: FormControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value == null) {
      return { error: true, pattern: true };
    }
    return {};
  };

  input(inputType: InputTypeEnum) {
    switch (inputType) {
      case InputTypeEnum.Account:
        this.setFormControls(inputType);
        break;
      case InputTypeEnum.Password:
        this.setFormControls(inputType);
        break;
      default:
        return;
    }
  }

  inputFocus(inputType: InputTypeEnum) {
    switch (inputType) {
      case InputTypeEnum.Account:
        this.isAccountFill = true;
        this.isAccountFocus = true;
        break;
      case InputTypeEnum.Password:
        this.isPasswordFill = true;
        this.isPasswordFocus = true;
        break;
      default:
        return;
    }
  }

  inputBlur(inputType: InputTypeEnum) {
    switch (inputType) {
      case InputTypeEnum.Account:
        this.isAccountFocus = false;
        break;
      case InputTypeEnum.Password:
        this.isPasswordFocus = false;
        break;
      default:
        return;
    }
    this.inputChange(inputType);
  }

  inputChange(inputType: InputTypeEnum) {
    switch (inputType) {
      case InputTypeEnum.Account:
        if (this.loginForm.controls[inputType].value) this.isAccountFill = true;
        else this.isAccountFill = false;
        break;
      case InputTypeEnum.Password:
        if (this.loginForm.controls[inputType].value) this.isPasswordFill = true;
        else this.isPasswordFill = false;
        break;
      default:
        return;
    }
  }

  inputKeyup(event: Event) {
    if (this.loginForm.invalid) event.preventDefault();
  }

  isError(inputType: InputTypeEnum) {
    return this.loginForm.controls[inputType].invalid && this.loginForm.controls[inputType].dirty;
  }

  setFormControls(inputType: InputTypeEnum | string) {
    this.loginForm.controls[inputType].markAsDirty();
    this.loginForm.controls[inputType].updateValueAndValidity();
  }

  openDialog(): void {
    const params = { expireTime: 3600 };
    this.messageSer.dialog$.next({
      component: LoginAuthenticatorComponent,
      config: {
        maxWidth: '420px',
        minWidth: '420px',
        maxHeight: '90vh',
        disableClose: true,
        data: {
          ...params,
        },
      },
      callback: ref => {
        ref
          .afterClosed()
          .pipe(filter(confirm => !!confirm))
          .subscribe({
            next: resp => {
              console.log('Dialog', resp);
              if (resp) this.router.navigate(['/trade']);
              this.reset();
            },
          });
      },
    });
  }

  reset() {
    console.log('close');
  }

  onSubmit() {
    for (const i in this.loginForm.controls) {
      this.setFormControls(i);
    }
    if (this.loginForm.invalid) return;
    this.userSer.login(this.loginForm.value).then((res: any) => {
      console.log('res', res);
      if (res && res.data.isShowAuthCode) this.openDialog();
    });
  }
}
