import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../core';

@Pipe({
  name: 'appAvatarFormat',
})
export class AvatarFormatPipe implements PipeTransform {
  transform(value: string): string {
    return Utils.avatarFormat(value);
  }
}
