import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { LocalStorageKey, StorageService } from '../core';
import { TranslationsHelper } from '../helper';
import { EnumItem } from '../types';
import langLib from './../language';
// import { NetworkService } from './network.service';

export enum LanguageEnum {
  ZH_CN = 'zh_CN',
  EN = 'en',
}

@Injectable({
  providedIn: 'root',
})
export class TranslationsService extends TranslationsHelper.GenericClass<typeof langLib>() {
  private defaultLang = {
    setStorage: false,
    val: 'zh_CN',
  };
  public langList: EnumItem[] = [];
  public curLang$: BehaviorSubject<string> = new BehaviorSubject('');
  public curLangFilter: EnumItem;

  get curLang(): string {
    return this.curLang$.value;
  }

  constructor(
    private translateSer: TranslateService,
    private storageSer: StorageService
    // private networkSer: NetworkService
  ) {
    super();
    this.langConfigPath();
  }

  public setLang(langId: string) {
    this.curLang$.next(langId);
    this.translateSer.setDefaultLang(langId);
    this.translateSer.use(langId);
    this.setLangStorage(langId);
  }

  public async get(keyString: string) {
    // const resultString = await this.translateSer.get(keyString).toPromise();
    const resultString = await lastValueFrom(this.translateSer.get(keyString));
    return resultString;
  }

  private async getLangStorage() {
    const storageLang = await this.storageSer.getItem<string>(LocalStorageKey.Lang);
    if (!storageLang) {
      this.curLang$.next(this.gatNavigatorLang() || this.defaultLang.val);
    } else {
      this.defaultLang.setStorage = true;
      this.curLang$.next(storageLang!);
    }
    this.setLang(this.curLang$.value);
  }

  // 取浏览器 navigator language
  private gatNavigatorLang() {
    const navigatorLang = navigator.language;
    return this.convertLangCode(navigatorLang);
  }

  // 转换语系代码
  private convertLangCode(lang: string): string {
    const zh: string[] = ['zh', 'zh-TW', 'zh-CN'];
    const en: string[] = ['en', 'en-US'];
    if (zh.includes(lang)) return 'zh_CN';
    if (en.includes(lang)) return 'en';
    return 'zh_CN';
  }

  private setLangStorage(langId: string): void {
    this.storageSer.setItem(LocalStorageKey.Lang, langId);
  }

  private langConfigPath() {
    this.getLangStorage();
    Object.assign(this, TranslationsHelper.transformObjectToPath('', langLib));
  }

  async getSupportedLang() {
    this.langList = [
      { key: LanguageEnum.ZH_CN, description: '繁体中文' },
      { key: LanguageEnum.EN, description: 'English' },
    ];
    //   const q = `
    //     query getLanguageList {
    //       options{
    //         supportedLanguageOptions{
    //           key
    //           description
    //         }
    //       }
    //     }
    //   `;
    //   const resp = await this.networkSer.graphQL.query(q);
    //   if (resp) {
    //     this.langList = resp?.options?.supportedLanguageOptions;
    //     this.defaultLang.val = this.langList[0].key;
    //     this.curLangFilter = this.langList.find(item => item.key === this.curLang$.value)!;
    //     if (!this.defaultLang.setStorage) this.setLang(this.defaultLang.val)
    //   }
  }
}
