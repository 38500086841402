import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Payment, PaymentEnum, TradeTypeEnum } from './category-options.service';

export interface Advertise {
  id: number;
  advertiseId: string;
  onLineState: boolean;
  currency: string;
  cryptocurrency: string;
  tradedQty: number;
  price: number | string;
  quantity: number | string;
  minLimit: number | string;
  maxLimit: number | string;
  paymentsList: Payment[];
  createdAt: number | string;
  updatedAt: number | string;
}

export interface PublishConfirmData extends Advertise {
  tradeType: TradeTypeEnum;
  cryptocurrencyId: number;
  cryptocurrencyLabel: string;
  currencyId: number;
  currencyLabel: string;
  expireTime: number;
  terms: string;
  checkOnlineId: number;
}

export interface PublishSuccessData extends PublishConfirmData {
  advertiseId: string;
}

export interface AdvertiseData extends Advertise {
  tradeType?: TradeTypeEnum;
}

@Injectable({
  providedIn: 'root',
})
export class AdvertiseService {
  advertise$ = new BehaviorSubject<AdvertiseData[]>([]);
  publishSuccessData$ = new BehaviorSubject<PublishSuccessData>(<PublishSuccessData>{});

  constructor() {
    this.getAdvertise();
  }

  getAdvertise() {
    const advertise = [
      {
        id: 1,
        advertiseId: '11161931387701628928',
        tradeType: TradeTypeEnum.Buy,
        onLineState: true,
        currency: 'PHP',
        cryptocurrency: 'USDT',
        tradedQty: 20.0,
        price: 32.23,
        quantity: 11985.61,
        minLimit: 300000.0,
        maxLimit: 450000.0,
        createdAt: 1715767464000,
        updatedAt: 1715940378000,
        paymentsList: [
          { id: PaymentEnum.Bank, label: '银行转账', color: 'yellow' },
          { id: PaymentEnum.AliPay, label: 'Alipay', color: 'blue' },
          { id: PaymentEnum.JkoPay, label: '街口转帐', color: 'pink' },
        ],
      },
      {
        id: 2,
        advertiseId: '11161931387701628928',
        tradeType: TradeTypeEnum.Sell,
        onLineState: true,
        currency: 'PHP',
        cryptocurrency: 'USDT',
        tradedQty: 20.0,
        price: 33.09,
        quantity: 34234.15,
        minLimit: 200000.0,
        maxLimit: 350000.0,
        createdAt: '2024/05/07 09:47:01',
        updatedAt: '2024/05/11 11:14:58',
        paymentsList: [
          { id: PaymentEnum.JkoPay, label: '街口转帐', color: 'pink' },
          { id: PaymentEnum.LineBank, label: 'LINE Bank', color: 'green' },
        ],
      },
      {
        id: 3,
        advertiseId: '11161931387701628928',
        tradeType: TradeTypeEnum.Sell,
        onLineState: false,
        currency: 'PHP',
        cryptocurrency: 'USDT',
        tradedQty: 20.0,
        price: 31.89,
        quantity: 45356.1,
        minLimit: 100000.0,
        maxLimit: 250000.0,
        createdAt: '2024/05/05 19:10:31',
        updatedAt: '2024/05/06 10:37:49',
        paymentsList: [
          { id: PaymentEnum.AliPay, label: 'Alipay', color: 'blue' },
          { id: PaymentEnum.LinePay, label: 'LINE Pay', color: 'green' },
        ],
      },
      {
        id: 4,
        advertiseId: '11161931387701628928',
        tradeType: TradeTypeEnum.Buy,
        onLineState: false,
        currency: 'PHP',
        cryptocurrency: 'USDT',
        tradedQty: 20.0,
        price: 32.23,
        quantity: 11985.61,
        minLimit: 300000.0,
        maxLimit: 450000.0,
        createdAt: '2024/05/03 17:01:28',
        updatedAt: '2024/05/04 10:09:21',
        paymentsList: [{ id: PaymentEnum.Bank, label: '银行转账', color: 'yellow' }],
      },
      {
        id: 5,
        advertiseId: '11161931387701628928',
        tradeType: TradeTypeEnum.Buy,
        onLineState: true,
        currency: 'CNY',
        cryptocurrency: 'BTC',
        tradedQty: 20.0,
        price: 32.23,
        quantity: 11985.61,
        minLimit: 300000.0,
        maxLimit: 450000.0,
        createdAt: '2024/05/1 12:17:25',
        updatedAt: '2024/05/2 21:13:47',
        paymentsList: [
          { id: PaymentEnum.AliPay, label: 'Alipay', color: 'blue' },
          { id: PaymentEnum.LineBank, label: 'LINE Bank', color: 'green' },
          { id: PaymentEnum.LinePay, label: 'LINE Pay', color: 'green' },
        ],
      },
    ];
    this.advertise$.next(advertise);
  }
}
