import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnumItem, LanguageEnum, Maybe, TranslationsService } from '@lib';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

interface LanguageData {
  langKey: LanguageEnum;
}

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss'],
})
export class SelectLanguageComponent implements OnInit {
  langKey: string = LanguageEnum.ZH_CN;
  searchLanguage: string | null = null;
  languagePlaceholder: string | null = null;
  langData: EnumItem | null = null;

  searchKey: Maybe<string> = '';
  dataList: EnumItem[] = [];
  private searchSubject: Subject<string | null> = new Subject<string | null>();

  constructor(
    @Optional() public dialogRef: MatDialogRef<SelectLanguageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LanguageData,
    public translationsSer: TranslationsService
  ) {
    this.searchSubject.pipe(debounceTime(500), distinctUntilChanged()).subscribe(searchKey => {
      this.search(searchKey);
    });
  }

  ngOnInit(): void {
    this.langKey = this.data.langKey;
    this.translationsSer.getSupportedLang();
    this.dataList = this.translationsSer.langList;
    this.setPlaceholder();
  }

  async setPlaceholder() {
    await this.translationsSer
      .get('inputText')
      .then(data => {
        // 语言
        this.languagePlaceholder = data.languagePlaceholder;
      })
      .catch(e => console.log(e));
  }

  changeSearch(event: Event) {
    this.searchSubject.next((event.target as HTMLInputElement).value);
  }

  search(searchKey: Maybe<string>) {
    const result: EnumItem[] = [];
    let item: EnumItem;
    for (let i = 0; i < this.translationsSer.langList.length; i++) {
      item = this.translationsSer.langList[i];
      if (item.description.toLowerCase().includes(searchKey!.toLowerCase())) result.push(item);
    }
    this.dataList = result;
  }

  clear() {
    this.searchLanguage = null;
    this.dataList = this.translationsSer.langList;
  }

  select(lang: EnumItem) {
    this.langKey = lang.key;
    this.langData = lang;
    this.translationsSer.setLang(lang.key);
    this.close();
    location.reload();
  }

  close() {
    this.dialogRef?.close(this.langData);
  }
}
