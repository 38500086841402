<div class="notify" style="min-width: {{ data.minWidth }}">
  <div class="notify-content">
    <mat-icon *ngIf="data.level">{{ data.level }}</mat-icon>
    <div *ngIf="data.showIcon" class="notify-icon">
      <div class="icon" inlineSVG="/assets/images/svgIcon/icon_error.svg"></div>
    </div>
    <div *ngIf="data.title" mat-dialog-title class="notify-title">{{ data.title }}</div>
    <div>
      <div *ngIf="data.textAsHtml" [innerHtml]="data.text | safeHtml"></div>
      <div *ngIf="!data.textAsHtml" class="notify-text">{{ data.text }}</div>
    </div>
  </div>

  <div *ngIf="data.confirmAction || data.cancelAction" mat-dialog-actions class="notify-button">
    <button
      *ngIf="data.cancelAction"
      mat-flat-button
      mat-dialog-close
      class="btn-flat-gray w-1/2 h-10"
      (click)="data.cancelAction()">
      {{ data.cancelText || (translationsSer.layout.cancel | translate) }}
    </button>
    <button
      *ngIf="data.confirmAction"
      mat-flat-button
      mat-dialog-close
      class="btn-flat-yellow w-1/2 h-10"
      (click)="data.confirmAction()"
      cdkFocusInitial>
      {{ data.confirmText || (translationsSer.layout.confirm | translate) }}
    </button>
  </div>
</div>
