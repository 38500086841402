import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifyMessage, TranslationsService } from '@lib';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss'],
})
export class NotifyComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NotifyMessage,
    public translationsSer: TranslationsService
  ) {}

  ngOnInit(): void {}
}
