import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface AccountManagement {
  id: number;
  currency: string;
  paymentType: string;
  account: string;
  isSwipeLeft: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AccountManagementService {
  accountManagement$ = new BehaviorSubject<AccountManagement[]>([]);

  constructor() {
    this.getAccountManagement();
  }

  getAccountManagement() {
    const accountManagement = [
      { id: 0, currency: 'PHP', paymentType: '银行卡', account: '1234567898765432', isSwipeLeft: false },
      { id: 1, currency: 'PHP', paymentType: '银行卡', account: '3456789876543212', isSwipeLeft: false },
      { id: 2, currency: 'PHP', paymentType: '银行卡', account: '5678987654321234', isSwipeLeft: false },
      { id: 3, currency: 'PHP', paymentType: '银行卡', account: '1234567898765432', isSwipeLeft: false },
      { id: 4, currency: 'PHP', paymentType: '银行卡', account: '3456789876543212', isSwipeLeft: false },
      { id: 5, currency: 'PHP', paymentType: '银行卡', account: '5678987654321234', isSwipeLeft: false },
    ];
    this.accountManagement$.next(accountManagement);
  }

  deleteAccount(id: number) {
    const filterData = this.accountManagement$.value.filter(d => d.id !== id);
    this.accountManagement$.next(filterData);
  }
}
