import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MessageService, NotifyMessage } from '@lib';
import { Subscription, timer } from 'rxjs';
import { NotifyComponent } from './shared/components/notify/notify.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'p2p-web';
  ref?: MatDialogRef<NotifyComponent> | null;
  timer?: Subscription;

  constructor(
    private messageSer: MessageService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    this.registerModal();
  }

  registerModal() {
    this.messageSer.bottomSheet$.subscribe(bottomSheetConfig => {
      const config = {
        hasBackdrop: true,
        disableClose: false,
        closeOnNavigation: true,
        ...bottomSheetConfig.config,
      };
      const ref = this.bottomSheet.open(bottomSheetConfig.component, config);
      bottomSheetConfig.callback?.(ref);
    });

    this.messageSer.dialog$.subscribe(dialogConfig => {
      const config = {
        hasBackdrop: true,
        disableClose: false,
        closeOnNavigation: true,
        ...dialogConfig.config,
      };
      const ref = this.dialog.open(dialogConfig.component, config);
      dialogConfig.callback?.(ref);
    });

    this.messageSer.notify$.subscribe(dialogConfig => {
      const config: MatDialogConfig<NotifyMessage> = {
        hasBackdrop: true,
        disableClose: false,
        closeOnNavigation: true,
        ...dialogConfig,
        data: {
          level: 'error',
          text: '',
          autoDismiss: 1000,
          ...dialogConfig.data,
        },
      };
      if (this.ref) {
        this.ref.close();
        this.ref = null;
        this.timer?.unsubscribe();
      }
      this.ref = this.dialog.open(NotifyComponent, config);
      if (config.data!.autoDismiss! > 0 && !config.data?.confirmAction) {
        this.timer = timer(config.data!.autoDismiss!).subscribe(() => {
          this.ref?.close();
          this.ref = null;
        });
      }
    });
  }
}
