import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { PipesModule } from '@lib';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { FooterComponent } from './components/footer/footer.component';
import { NavTopComponent } from './components/nav-top/nav-top.component';
import { NotifyComponent } from './components/notify/notify.component';
import { SelectLanguageComponent } from './components/select-language/select-language.component';
import { SelectMainnetComponent } from './components/select-mainnet/select-mainnet.component';
import { MaterialModule } from './materialModule';

const module = [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, PipesModule];

@NgModule({
  declarations: [NavTopComponent, FooterComponent, NotifyComponent, SelectMainnetComponent, SelectLanguageComponent],
  imports: [...module, MaterialModule, InlineSVGModule.forRoot(), TranslateModule.forChild()],
  exports: [...module, MaterialModule, InlineSVGModule, TranslateModule, NavTopComponent, FooterComponent],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'zh-CN' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'YYYY/MM/DD',
        },
        display: {
          dateInput: 'YYYY/MM/DD',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ],
})
export class SharedModule {}
