<app-nav-top></app-nav-top>
<div class="register">
  <div class="register-title">{{ translationsSer.register.title | translate }}</div>
  <form
    class="form-deep"
    [formGroup]="registerForm"
    (ngSubmit)="registerForm.valid && onSubmit()"
    (keydown.enter)="inputKeyup($event)">
    <div
      class="input-stroked-deep input-form"
      [class.selected]="isAccountFocus"
      [class.error]="isError(inputType.Account)">
      <label for="account" [class.fill]="isAccountFill">{{ translationsSer.inputText.account | translate }}</label>
      <div class="input-block">
        <input
          type="text"
          [(ngModel)]="account"
          id="account"
          formControlName="account"
          [placeholder]="accountPlaceholder"
          (focus)="inputFocus(inputType.Account)"
          (blur)="inputBlur(inputType.Account)"
          (input)="input(inputType.Account)" />
        <span>{{ '' }}</span>
      </div>
      <!-- msg -->
      <div *ngIf="isError(inputType.Account)" class="msg">
        <div *ngIf="registerForm.hasError('required', 'account')">
          {{ translationsSer.inputText.accountRequired | translate }}
        </div>
        <div *ngIf="registerForm.hasError('pattern', 'account')">{{ 'error' }}</div>
      </div>
    </div>
    <div
      class="input-stroked-deep input-form"
      [class.selected]="isPasswordFocus"
      [class.error]="isError(inputType.Password)">
      <label for="password" [class.fill]="isPasswordFill">{{ translationsSer.inputText.password | translate }}</label>
      <div class="input-block">
        <input
          type="password"
          [(ngModel)]="password"
          id="password"
          formControlName="password"
          [placeholder]="passwordPlaceholder"
          (focus)="inputFocus(inputType.Password)"
          (blur)="inputBlur(inputType.Password)"
          (input)="input(inputType.Password)" />
      </div>
      <!-- msg -->
      <div *ngIf="isError(inputType.Password)" class="msg">
        <div *ngIf="registerForm.hasError('required', 'password')">
          {{ translationsSer.inputText.passwordRequired | translate }}
        </div>
        <div *ngIf="registerForm.hasError('pattern', 'password')">{{ 'error' }}</div>
      </div>
    </div>
    <div
      class="input-stroked-deep input-form"
      [class.selected]="isConfirmPasswordFocus"
      [class.error]="isError(inputType.ConfirmPassword)">
      <label for="confirmPassword" [class.fill]="isConfirmPasswordFill">{{
        translationsSer.inputText.confirmPassword | translate
      }}</label>
      <div class="input-block">
        <input
          type="password"
          [(ngModel)]="confirmPassword"
          id="confirmPassword"
          formControlName="confirmPassword"
          [placeholder]="confirmPasswordPlaceholder"
          (focus)="inputFocus(inputType.ConfirmPassword)"
          (blur)="inputBlur(inputType.ConfirmPassword)"
          (input)="input(inputType.ConfirmPassword)" />
      </div>
      <!-- msg -->
      <div *ngIf="isError(inputType.ConfirmPassword)" class="msg">
        <div *ngIf="registerForm.hasError('required', 'confirmPassword')">
          {{ translationsSer.inputText.confirmPasswordRequired | translate }}
        </div>
        <div *ngIf="registerForm.hasError('pattern', 'confirmPassword')">{{ 'error' }}</div>
      </div>
    </div>
    <div
      class="input-stroked-deep input-form"
      [class.selected]="isInvitationCodeFocus"
      [class.error]="isError(inputType.InvitationCode)">
      <label for="invitationCode" [class.fill]="isInvitationCodeFill">{{
        translationsSer.inputText.invitationCode | translate
      }}</label>
      <div class="input-block">
        <input
          type="password"
          [(ngModel)]="invitationCode"
          id="invitationCode"
          formControlName="invitationCode"
          [placeholder]="invitationCodePlaceholder"
          (focus)="inputFocus(inputType.InvitationCode)"
          (blur)="inputBlur(inputType.InvitationCode)"
          (input)="input(inputType.InvitationCode)" />
      </div>
      <!-- msg -->
      <div *ngIf="isError(inputType.InvitationCode)" class="msg">
        <div *ngIf="registerForm.hasError('required', 'invitationCode')">
          {{ translationsSer.inputText.invitationCodeRequired | translate }}
        </div>
        <div *ngIf="registerForm.hasError('pattern', 'invitationCode')">{{ 'error' }}</div>
      </div>
    </div>
    <button
      mat-flat-button
      type="submit"
      class="w-full h-10"
      [class]="onSubmitStyle"
      (click)="onSubmit()"
      [disabled]="registerForm.invalid">
      {{ translationsSer.layout.nextStep | translate }}
    </button>
  </form>
  <div class="flex justify-start items-center gap-3">
    <button class="router-link" routerLink="/login">{{ translationsSer.register.goToLogin | translate }}</button>
  </div>
</div>
<app-footer></app-footer>
