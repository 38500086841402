import { Injectable } from '@angular/core';

export enum LocalStorageKey {
  Token,
  UserInfo,
  Lang,
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  private getRealKey(key: LocalStorageKey) {
    return `p2p-app-${key}`;
  }

  getItem<T = any>(key: LocalStorageKey): T | undefined {
    try {
      return JSON.parse(localStorage.getItem(this.getRealKey(key))!);
    } catch {
      return undefined;
    }
  }

  setItem<T = any>(key: LocalStorageKey, value: T) {
    localStorage.setItem(this.getRealKey(key), JSON.stringify(value));
  }

  removeItem(key: LocalStorageKey) {
    localStorage.removeItem(this.getRealKey(key));
  }

  clear() {
    localStorage.clear();
  }
}
