<div class="nav-top">
  <!-- Nav Top Left -->
  <div class="nav-top-left">
    <div class="nav-logo" routerLink="/trade">P2P APP</div>
    <!-- <div class="nav-menu">
      <div
        *ngFor="let item of menuList"
        class="nav-menu-btn"
        [class.active]="routerLinkActive(item.router)"
        [routerLink]="item.router">
        {{ item.label }}
      </div>
    </div> -->
  </div>

  <!-- Nav Top Right -->
  <div class="nav-top-right" [ngSwitch]="isHadToken">
    <div class="nav-menu">
      <div
        *ngFor="let item of menuList"
        class="nav-menu-btn"
        [class.active]="routerLinkActive(item.router)"
        [routerLink]="item.router">
        {{ item.label }}
      </div>
    </div>

    <button *ngSwitchCase="false" mat-flat-button class="btn-flat-yellow px-2 h-8" routerLink="/login">
      <mat-icon>login</mat-icon>
      {{ translationsSer.navTop.login | translate }}
    </button>

    <button *ngSwitchCase="false" mat-flat-button class="btn-flat-yellow px-2 h-8" routerLink="/register">
      <mat-icon>redeem</mat-icon>
      {{ translationsSer.navTop.register | translate }}
    </button>

    <button *ngSwitchCase="true" class="menu-open-btn" [matMenuTriggerFor]="menu">
      <mat-icon class="nav-icon">menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button *ngFor="let item of toolsList" mat-menu-item (click)="choseTool(item.type)">
        <mat-icon>{{ item.icon }}</mat-icon>
        {{ item.label }}
      </button>
    </mat-menu>
  </div>
</div>
