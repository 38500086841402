import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../core';

@Pipe({
  name: 'appExpireTimeFormat',
})
export class ExpireTimeFormatPipe implements PipeTransform {
  transform(value: number, isTextFormat: boolean): string {
    return Utils.expireTimeFormat(value, isTextFormat);
  }
}
